import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import apiClient from '../apiClient';
import { toast } from 'react-toastify';

const ShiftList = () => {
  const [faculties, setFaculties] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState('');
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [filteredShifts, setFilteredShifts] = useState([]);

  useEffect(() => {
    apiClient.get('api/faculties/')
      .then(response => setFaculties(response.data))
      .catch(error => console.error('Error fetching faculties:', error));

    fetchShifts();
  }, []);

  useEffect(() => {
    if (selectedFaculty) {
      apiClient.get(`api/courses/${selectedFaculty}/filter/`)
        .then(response => setCourses(response.data.courses))
        .catch(error => console.error('Error fetching courses:', error));
    } else {
      setCourses([]);
    }
  }, [selectedFaculty]);

  useEffect(() => {
    if (selectedFaculty && selectedCourse) {
      apiClient.get(`api/shifts-filter/${selectedFaculty}/${selectedCourse}/`)
        .then(response => {
          const activeShifts = response.data.filter(shift => shift.is_active);
          setFilteredShifts(activeShifts);
        })
        .catch(error => console.error('Error fetching filtered shifts:', error));
    } else {
      fetchShifts();
    }
  }, [selectedFaculty, selectedCourse]);

  const fetchShifts = () => {
    apiClient.get('api/shifts-list/')
      .then(response => {
        const activeShifts = response.data.filter(shift => shift.is_active);
        setFilteredShifts(activeShifts);
      })
      .catch(error => console.error('Error fetching shifts:', error));
  };

  const handleRemoveShift = (id) => {
    apiClient.put(`api/shifts/${id}/delete/`, { is_active: false })
      .then(() => {
        toast.success("Shift Deleted Successfully");
        setFilteredShifts(prevShifts => prevShifts.filter(shift => shift.id !== id));
      })
      .catch(error => console.error('Error removing shift:', error));
  };

  const capitalizeTitle = (title) => 
    title.replace(/\b\w/g, char => char.toUpperCase());

  return (
    <>
      <div className="box mt-3">
        <div className="row">
          <div className="col-6">
            <div className="form-group mb-3">
              <label htmlFor="faculty" className="form-label" style={{fontWeight:"600"}}>Faculty:</label>
              <select
                id="faculty"
                className="form-control"
                value={selectedFaculty}
                onChange={e => setSelectedFaculty(e.target.value)}
              >
                <option value="">All Faculties</option>
                {faculties.map(faculty => (
                  <option key={faculty.id} value={faculty.id}>{capitalizeTitle(faculty.faculty)}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group mb-3">
              <label htmlFor="course" className="form-label">Course:</label>
              <select
                id="course"
                className="form-control"
                value={selectedCourse}
                onChange={e => setSelectedCourse(e.target.value)}
                disabled={!selectedFaculty}
              >
                <option value="">All Courses</option>
                {courses.map(course => (
                  <option key={course.id} value={course.id}>{capitalizeTitle(course.title)}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Faculty</th>
              <th>Course Title</th>
              <th>Shift Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredShifts.length > 0 ? (
              filteredShifts.map((shift, index) => (
                <tr key={shift.id}>
                  <td>{index + 1}</td>
                  <td>{capitalizeTitle(shift.faculty.faculty)}</td>
                  <td>{capitalizeTitle(shift.course.title)}</td>
                  <td>{shift.shiftname}</td>
                  <td>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => handleRemoveShift(shift.id)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className='text-center'>No Current Shift Available</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default ShiftList;
