import React, { useState, useEffect } from 'react';
import apiClient from '../apiClient';

const ActiveCourseInfo = ({ userId }) => {
    const [courseData, setCourseData] = useState([]);

    useEffect(() => {
        const fetchCourseData = async () => {
            try {
                const response = await apiClient.get(`api/get-user-course-selected/${userId}/`);
                setCourseData(response.data);
            } catch (error) {
                console.error('Error fetching course data:', error);
            }
        };

        if (userId) {
            fetchCourseData();
        }
    }, [userId]);

    if (!courseData) {
        return <div>Loading...</div>;
    }
    const capitalizeTitle = (title) => 
        title.replace(/\b\w/g, char => char.toUpperCase());
    return (
        <div>
            <div className="row">
                <div className="col-12 mb-3 my-3" style={{ background: '#27AE60' }}>
                    <p style={{ padding: '5px 10px', margin: '0', color: 'white' }}><strong>Course Information</strong></p>
                </div>
                <div className="col-12">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Course Title</th>
                                <th>Shift</th>
                                <th>Joined Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {courseData.map((course, index) => (
                                <tr key={course.id}>
                                    <td>{index + 1}</td>
                                    <td>{capitalizeTitle(course.course.title)}</td>
                                    <td>{capitalizeTitle(course.shift.shiftname)}</td>
                                    <td>{course.date}</td>
                                    <td>{course.active_status ? 'Active' : 'Inactive'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default ActiveCourseInfo;
