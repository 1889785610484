import React, { useState, useEffect } from 'react';
import apiClient from '../apiClient';
import { toast } from 'react-toastify';

const CreateStaff = () => {
    const [formData, setFormData] = useState({
        full_name: '',
        email: '',
        username: '',
        phone: '',
        address: '',
        guardian: '',
        remark: ''
    });
    const [errors, setErrors] = useState({
        email: '',
        phone: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [phoneValidationMessage, setPhoneValidationMessage] = useState('');
    const [usernameValidationMessage, setUsernameValidationMessage] = useState('');


    useEffect(() => {
        console.log('CreateUser component mounted');
    }, []); 

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    
        if (name === 'phone') {
            handlePhoneValidation(value);
        } else if (name === 'username') {
            handleUsernameValidation(value);
        }
    };
    

    const handlePhoneValidation = (phone) => {
        const phoneRegex = /^\d{10}$/; 
        if (!phoneRegex.test(phone)) {
            setPhoneValidationMessage('Invalid phone number');
        } else {
            console.log('Phone number:', phone);
            apiClient.get(`api/check-phone-exists/${phone}/`)
                .then(response => {
                    if (response.data.exists) {
                        setPhoneValidationMessage('Number already exists');
                    } else {
                        setPhoneValidationMessage('Available');
                    }
                })
                .catch(error => {
                    console.error('Error checking phone number:', error);
                });
        }
    };
    const handleUsernameValidation = (username) => {
        if (username.length < 4) { 
            setUsernameValidationMessage('Username must be at least 4 characters');
        } else {
            apiClient.get(`api/check-username-exists/${username}/`)
                .then(response => {
                    if (response.data.exists) {
                        setUsernameValidationMessage('Username already exists');
                    } else {
                        setUsernameValidationMessage('Username is available');
                    }
                })
                .catch(error => {
                    console.error('Error checking username:', error);
                    setUsernameValidationMessage('');
                });
        }
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            setErrors({ ...errors, email: 'Email is not in the correct format' });
            return;
        }
        
        setErrors(prevErrors => ({ ...prevErrors, email: '', phone: ''}));
        setIsSubmitting(true); 
        try {
            const response = await apiClient.post('api/register-staff/', formData);
            console.log('User created:', response.data);
            setFormData({
                full_name: '',
                email: '',
                username: '',
                phone: '',
                address: '',
                guardian: '',
                remark: ''
            });
            toast.success('Staff created successfully!');
        } catch (error) {
            console.error('Error creating user:', error);
            toast.error('Username or Email or Phone number already exist.');
        } finally {
            setIsSubmitting(false); 
        }
    };

  return (
    <div>
        <div className="container">
            <h5>Create Staff</h5>
            <div className="row">
            <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-6 mb-2">
                                    <div className="form-group">
                                        <label htmlFor="full_name" className='form-label'>Full Name</label>
                                        <input type="text" className='form-control' name='full_name' value={formData.full_name} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="col-6 mb-2">
                                    <div className="form-group">
                                      <label htmlFor="email" className='form-label'>Email <span className='available-error'>{errors.email}</span></label>
                                        <input type="email" className='form-control' name='email' value={formData.email} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="col-6 mb-2">
                                    <label htmlFor="username" className='form-label'>Username <span className={usernameValidationMessage === 'Username is available' ? 'available' : 'available-error'}>{usernameValidationMessage}</span></label>
                                    <input type="text" className='form-control' name='username' value={formData.username} onChange={handleChange} />
                                </div>

                                <div className="col-6 mb-2">
                                    <label htmlFor="phone" className='form-label'>Phone <span className={phoneValidationMessage === 'Available' ? 'available' : 'available-error'}>{phoneValidationMessage}</span></label>
                                    <input type="number" className='form-control' name='phone' value={formData.phone} onChange={handleChange} />

                                </div>
                                <div className="col-6 mb-2">
                                    <label htmlFor="address" className='form-label'>Address</label>
                                    <input type="text" className='form-control' name='address' value={formData.address} onChange={handleChange} />
                                </div>
                                <div className="col-6 mb-2">
                                    <label htmlFor="guardian" className='form-label'>Guardian</label>
                                    <input type="text" className='form-control' name='guardian' value={formData.guardian} onChange={handleChange} />
                                </div>
                                <div className="col-8">
                                    <label htmlFor="remark" className='form-label'>Remark</label>
                                    <textarea name="remark" id="remark" className='form-control' cols="30" rows="4" value={formData.remark} onChange={handleChange}></textarea>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-end">
                                <div className="col-auto">
                                    <button type="submit" className="btn btn-primary mt-3" disabled={isSubmitting || phoneValidationMessage !== 'Available' || usernameValidationMessage !== 'Username is available'}>
                                        {isSubmitting ? 'Submitting...' : 'Submit'}
                                    </button>
                                </div>
                            </div>
                            </form>  
            </div>
        </div>
      
    </div>
  )
}

export default CreateStaff
