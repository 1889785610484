import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Home from './components/Home/Home';
import Students from './components/Users/Students';
import Users from './components/Users/Users';
import Courses from './components/Courses/Courses';
import Questions from './components/Questions/Questions';
import Exams from './components/Exams/Exams';
import Billing from './components/Billing/Billing';
import Requests from './components/Home/Requests';
import Settings from './components/Settings/Settings';
import AddCourse from './components/Courses/AddCourses'; 
import EditCourse from './components/Courses/EditCourse';
import LoginUser from './components/LoginUser/LoginUser'; 
import NotFound from './components/NotFound/NotFound'; 
import 'react-toastify/dist/ReactToastify.css';
import CreateUser from './components/Users/CreateUser';
import Site from './components/Site/Site';
import TestApi from './components/TestApi/TestApi';
import DueListUsers from './components/Billing/DueListUsers';
import AboutInstitute from './components/AboutInstitute/AboutInstitute';
import Syllabus from './components/Syllabus/Syllabus';
import Testimonials from './components/Testimonials/Testimonials';
import Events from './components/Events/Events';
import EventPost from './components/Events/EventPost';
import CreateQuestion from './components/Questions/CreateQuestion';
import ManageAds from './components/ManageAds/ManageAds';
import ManageDownloads from './components/Site/ManageDownloads';
import Messages from './components/Messages/Messages';
import Page from './components/Home/Page';
import { ToastProvider } from './components/ToastContext/ToastContext';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userLoggedIn = localStorage.getItem('user') !== null;
    setLoggedIn(userLoggedIn);
    setLoading(false); 
  }, []);

  if (loading) {
    return <div>Loading...</div>; 
  }

  return (
    <ToastProvider>
    <Router>
      <Routes>
        <Route path="/login" element={<LoginUser setLoggedIn={setLoggedIn} />} />
        <Route path="/dashboard" element={loggedIn ? <Home /> : <Navigate to="/login"/>} />
        <Route path="/students" element={loggedIn ? <Students /> : <Navigate to="/login"/>} />
        <Route path="/users" element={loggedIn ? <Users /> : <Navigate to="/login"/>} />
        <Route path="/courses" element={loggedIn ? <Courses /> : <Navigate to="/login"/>} />
        <Route path="/questions" element={loggedIn ? <Questions /> : <Navigate to="/login"/>} />
        <Route path="/schedule-exams" element={loggedIn ? <Exams /> : <Navigate to="/login"/>} />
        <Route path="/billing" element={loggedIn ? <Billing /> : <Navigate to="/login"/>} />
        <Route path="/requests" element={loggedIn ? <Requests /> : <Navigate to="/login"/>} />
        <Route path="/settings" element={loggedIn ? <Settings /> : <Navigate to="/login"/>} />
        <Route path="/site" element={loggedIn ? <Site /> : <Navigate to="/login"/>} />
        <Route path="/test-api" element={loggedIn ? <TestApi /> : <Navigate to="/login"/>} />

        {/* external route */}
        <Route path="/users/create-user" element={loggedIn ? <CreateUser /> : <Navigate to="/login"/>} />
        <Route path="/courses/add-course" element={loggedIn ? <AddCourse /> : <Navigate to="/login"/>} />
        <Route path="/courses/edit-course" element={loggedIn ? <EditCourse />: <Navigate to="/login"/>} />
        <Route path="/billing/due-payment-users" element={loggedIn ? <DueListUsers />: <Navigate to="/login"/>} />
        <Route path="/site/about-institute" element={loggedIn ? <AboutInstitute />: <Navigate to="/login"/>} />
        <Route path="/site/events" element={loggedIn ? <Events />: <Navigate to="/login"/>} />
        <Route path="/site/events/add-post" element={loggedIn ? <EventPost />: <Navigate to="/login"/>} />
        <Route path="/site/syllabus" element={loggedIn ? <Syllabus />: <Navigate to="/login"/>} />
        <Route path="/site/testimonials" element={loggedIn ? <Testimonials />: <Navigate to="/login"/>} />
        <Route path="/questions/add-question" element={loggedIn ? <CreateQuestion />: <Navigate to="/login"/>} />
        <Route path="/site/manage-ads" element={loggedIn ? <ManageAds />: <Navigate to="/login"/>} />
        <Route path="/site/downloads" element={loggedIn ? <ManageDownloads />: <Navigate to="/login"/>} />
        <Route path="/messages" element={loggedIn ? <Messages />: <Navigate to="/login"/>} />
        <Route path="/page" element={loggedIn ? <Page />: <Navigate to="/page"/>} />

        <Route path="/" element={loggedIn ? <Navigate to="/dashboard" /> : <Navigate to="/login" />} />
        {/* Catch-all route for 404 page not found */}
        <Route path="*" element={<NotFound />} />
      </Routes>   
    </Router>
    </ToastProvider>
  );
}

export default App;
