import React, { useState, useEffect } from 'react';
import apiClient from '../apiClient';
import { Modal } from 'react-bootstrap'; 
import { toast } from 'react-toastify';

const ViewQuestions = ({ selectedSetId }) => {
  const [questions, setQuestions] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [delQuestionId, setSelectedQuestionDelete] = useState(null);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await apiClient.get(`api/questions/${selectedSetId}/`);
        setQuestions(response.data);
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    };

    if (selectedSetId) {
      fetchQuestions();
    }
  }, [selectedSetId]);

  const handleEditQuestion = (questionId) => {
    setSelectedQuestionDelete(questionId); 
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };
  
  const handleDelete = async () => {
    try {
      await apiClient.delete(`api/questions/delete/${delQuestionId}/`); 
      setShowEditModal(false);
      toast.success('Question deleted successfully!');
      const response = await apiClient.get(`api/questions/${selectedSetId}/`);
      setQuestions(response.data);
    } catch (error) {
      console.error('Error deleting question:', error);
    }
  };

  return (
    <div>
      {questions.map((question, index) => (
        <div className="box" key={index}>
          <div className="row d-flex justify-content-between">
            <div className="col-auto">
              <h5>Question No. {index + 1}</h5>
            </div>
            <div className="col-auto">
              <h5>Question Model: {question.question_model}</h5>
            </div>
          </div>
          {question.label && (
            <div className="row">
                <p dangerouslySetInnerHTML={{ __html: question.label }}></p>
            </div>
          )}
          <div className="row">
            <div className="col-6">
              {question.question_type === 'text' && (
                <div className="row">
                  <p>{question.question_text}</p>
                </div>
              )}
              {question.question_type === 'audio' && (
                <div className="row">
                  <audio src={question.question_audio} controls />
                </div>
              )}
              {question.question_type === 'image' && (
                <div className="row">
                  <img src={question.question_image} alt="" />
                </div>
              )}
            </div>
            <div className="col-6">
            {question.answer_type === 'text' && (
                <>
                    <div className="row">
                        <p>Option 1: {question.option1_text}</p>
                    </div>
                    <div className="row">
                        <p>Option 2: {question.option2_text}</p>
                    </div>
                    <div className="row">
                        <p>Option 3: {question.option3_text}</p>
                    </div>
                    <div className="row">
                        <p>Option 4: {question.option4_text}</p>
                    </div>
                </>
            )}
            {question.answer_type === 'audio' && (
                <>
                    <div className="row">
                    Option1: <audio src={question.option1_audio} controls />
                    </div>
                    <div className="row">
                    Option2: <audio src={question.option2_audio} controls />
                    </div>
                    <div className="row">
                    Option3: <audio src={question.option3_audio} controls />
                    </div>
                    <div className="row">
                    Option4: <audio src={question.option4_audio} controls />
                    </div>
                </>
                )}
                {question.answer_type === 'image' && (
                    <>
                    <div className="row mb-2">
                        <div className="col-6">
                            <p>Option 1</p>
                            <img src={question.option1_image} alt="" style={{ width: '100%', height: 'auto' }} />
                        </div>
                        <div className="col-6">
                            <p>Option 2</p>
                            <img src={question.option2_image} alt="" style={{ width: '100%', height: 'auto' }} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <p>Option 3</p>
                            <img src={question.option3_image} alt="" style={{ width: '100%', height: 'auto' }} />
                        </div>
                        <div className="col-6">
                            <p>Option 4</p>
                            <img src={question.option4_image} alt="" style={{ width: '100%', height: 'auto' }} />
                        </div>
                    </div>
                    </>
                )}

              
            </div>
            <div className="row d-flex justify-content-between">
                <div className="col-auto">
                    <p>Answer: <b>{question.answer && question.answer.toUpperCase()}</b></p>
                </div>
                <div className="col-auto">
                <button className='btn btn-sm btn-danger' onClick={() => handleEditQuestion(question.id)}>Delete Question</button>
                </div>
            </div>
          </div>
        </div>
      ))}
       <Modal show={showEditModal} onHide={handleCloseEditModal} keyboard={false} backdrop="static" style={{background:'rgba(0, 0, 0, 0.3)'}}>
            <Modal.Header closeButton>
                <Modal.Title>Are you sure you want to delete this question?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <button className='btn btn-danger' onClick={handleDelete}>Yes Confirm</button>
                <button className='btn btn-secondary' onClick={handleCloseEditModal}>Cancel</button>
            </Modal.Footer>
        </Modal>
    </div>
  );
};

export default ViewQuestions;
