import React, { useState, useEffect } from 'react';
import Navbar from '../Navbars/Navbar';
import Sidebar from '../Navbars/Sidebar';
import Table from 'react-bootstrap/Table'; 
import apiClient from '../apiClient';

const DueListUsers = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [faculties, setFaculties] = useState([]);
  const [courses, setCourses] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedShift, setSelectedShift] = useState('');
  const [usersData, setUsersData] = useState([]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    fetchFaculties();
    fetchCourses();
    fetchShifts();
  }, []);

  const fetchFaculties = () => {
    apiClient.get('api/faculties/')
      .then(response => {
        setFaculties(response.data);
      })
      .catch(error => console.error('Error fetching faculties:', error));
  };

  const fetchCourses = () => {
    apiClient.get('api/all-course/')
      .then(response => {
        setCourses(response.data);
      })
      .catch(error => console.error('Error fetching courses:', error));
  };

  const fetchShifts = () => {
    apiClient.get('shifts-list/')
      .then(response => {
        setShifts(response.data);
      })
      .catch(error => console.error('Error fetching shifts:', error));
  };

  const handleFacultyChange = (event) => {
    setSelectedFaculty(event.target.value);
  };

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
  };

  const handleShiftChange = (event) => {
    setSelectedShift(event.target.value);
  };

  const filterUsersData = () => {
   
    apiClient.get('api/user-data/', {
      params: {
        faculty: selectedFaculty,
        course: selectedCourse,
        shift: selectedShift
      }
    })
    .then(response => {
      setUsersData(response.data);
    })
    .catch(error => console.error('Error fetching users data:', error));
  };
  

  return (
    <div className="main">
      <Sidebar isOpen={isSidebarOpen} />
      <div className="content">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="container-fluid px-4 py-3">
          <div className="row">
            <h1>Due Payment List</h1>
          </div>
          <div className="row my-3">
            <div className="col">
              <label htmlFor="selectFaculty" className='form-label'>Select Faculty</label>
              <select name="selectfaculty" id="selectfaculty" className='form-control' onChange={handleFacultyChange}>
                <option value="">Select Faculty</option>
                {faculties.map(faculty => (
                  <option key={faculty.id} value={faculty.id}>{faculty.faculty}</option>
                ))}
              </select>
            </div>
            <div className="col">
              <label htmlFor="selectCourse" className='form-label'>Select Course</label>
              <select name="selectCourse" id="selectCourse" className='form-control' onChange={handleCourseChange}>
                <option value="">Select Course</option>
                {courses.map(course => (
                  <option key={course.id} value={course.id}>{course.title}</option>
                ))}
              </select>
            </div>
            <div className="col">
              <label htmlFor="selectShift" className='form-label'>Select Shift</label>
              <select name="selectShift" id="selectShift" className='form-control' onChange={handleShiftChange}>
                <option value="">Select Shift</option>
                {shifts.map(shift => (
                  <option key={shift.id} value={shift.id}>{shift.shiftname}</option>
                ))}
              </select>
            </div>
            <div className="col">
              <button className="btn btn-primary" onClick={filterUsersData}>Filter</button>
            </div>
          </div>
          <div className="box">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Total Due</th>
                  <th>Total Paid Amount</th>
                  <th>Total Courses Joined</th>
                  <th>Contact</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {usersData.map((user, index) => (
                    <tr key={user.id}>
                    <td>{index + 1}</td>
                    <td>{user.name}</td>
                    <td>{user.total_due}</td>
                    <td>{user.total_paid_amount}</td>
                    <td>{user.total_courses_joined}</td>
                    <td>{user.contact}</td>
                    <td>Action</td>
                    </tr>
                ))}
            </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DueListUsers;
