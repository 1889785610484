import React, { useState, useEffect } from 'react';
import apiClient from '../apiClient';
import './Message.css';

const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true, 
  };
  return date.toLocaleString('en-US', options);
};

const GetMessageHistroy = () => {
  const [messages, setMessages] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');

  useEffect(() => {
    fetchMessages(selectedDate);
  }, [selectedDate]);
  
  const fetchMessages = (date) => {
    let url = 'api/last-messages/';
    if (date) {
      url = `api/messages-by-date/${date}/`;
    }
    apiClient.get(url)
      .then(response => {
        console.log(response.data);
        if (Array.isArray(response.data)) {
          setMessages(response.data);
        } else {
          setMessages([]); 
        }
      })
      .catch(error => {
        console.error('Error fetching messages:', error);
        setMessages([]); 
      });
  };
  
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  return (
    <div>
      <div className="row d-flex justify-content-end align-items-center">
        <div className="col-auto">
          <label htmlFor="date" className="form-label">Filter Date</label>
        </div>
        <div className="col-3">
          <input type="date" id="date" className="form-control" onChange={handleDateChange} value={selectedDate} />
        </div>
      </div>

      <table className="message-table mt-3">
        <thead>
          <tr>
            <th>#</th>
            <th>Timestamp</th>
            <th>User</th>
            <th>Phone</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          {messages.map((message, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{formatTimestamp(message.timestamp)}</td>
              <td>{message.user.full_name}</td>
              <td>{message.phone}</td>
              <td>{message.message}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GetMessageHistroy;
