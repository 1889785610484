import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; 
import Navbar from '../Navbars/Navbar';
import Sidebar from '../Navbars/Sidebar';
import { Tab, Tabs } from 'react-bootstrap';
import AllCourseList from './AllCourseList';
import { ToastContainer, toast } from 'react-toastify';
import { useToast } from '../ToastContext/ToastContext';
import 'react-toastify/dist/ReactToastify.css';
import Shifts from './Shifts';

const Courses = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [key, setKey] = useState('all');
  const { setToastMessage } = useToast();
  const location = useLocation();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    if (location.state && location.state.toastMessage) {
      toast.success(location.state.toastMessage);
      setToastMessage(null);
    }
  }, [location.state, setToastMessage]);

  return (
    <div className="main">
      <Sidebar isOpen={isSidebarOpen} />
      <div className="content">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="container-fluid px-4 py-3">
          <div className="row my-2 align-items-center d-block">
            <div className="col-md-12 d-flex justify-content-between">
              <h1>Course List</h1>
              <Link className='btn btn-success btn-sm' to="/courses/add-course">Add Course</Link>
            </div>
          </div>
          <>
            <Tabs
              id="controlled-tabs"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="all" title="All Courses">
                <AllCourseList/>
              </Tab>
              <Tab eventKey="inactive" title="Manage Shift">
                <Shifts/>
              </Tab>
            </Tabs>
            <ToastContainer />
          </>
        </div>
      </div>
    </div>
  );
};

export default Courses;
