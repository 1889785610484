import React, { useState, useEffect } from 'react';
import apiClient from '../apiClient';

const ManageFaculty = () => {
  const [facultyName, setFacultyName] = useState('');
  const [faculties, setFaculties] = useState([]);

  useEffect(() => {
    fetchFaculties();
  }, []);

  const fetchFaculties = async () => {
    try {
      const response = await apiClient.get('api/faculties/');
      setFaculties(response.data);
    } catch (error) {
      console.error('Error fetching faculties:', error);
    }
  };

  const handleFacultyNameChange = (event) => {
    setFacultyName(event.target.value);
  };

  const addFaculty = async (event) => {
    event.preventDefault();
    try {
      const response = await apiClient.post('api/faculty/create/', { faculty: facultyName });
      setFaculties([...faculties, response.data]);
      setFacultyName('');
      fetchFaculties();
    } catch (error) {
      console.error('Error adding faculty:', error);
    }
  };

  const deleteFaculty = async (facultyId) => {
    try {
      await apiClient.delete(`api/faculty/${facultyId}/delete/`);
      setFaculties(faculties.filter(faculty => faculty.id !== facultyId));
    } catch (error) {
      console.error('Error deleting faculty:', error);
    }
  };

  return (
    <div>
      <div className="container">
        <h5>Manage Faculties</h5>
        <div className="row my-3">
          <form onSubmit={addFaculty} className='d-flex justify-content-end align-items-center'>
            <label htmlFor="facultyName" className='form-group'>Faculty:</label>
            <input 
                type="text" 
                name='faculty' 
                className='form-control form-control-sm' 
                value={facultyName}
                onChange={handleFacultyNameChange} 
              />
              <button type="submit" className='btn btn-success btn-sm ml-2'> 
                Add Faculty
              </button>
          </form>
        </div>
        <div className="row px-4 py-4">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Faculty Title</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {faculties.map((faculty, index) => (
                <tr key={faculty.id}>
                  <td>{index + 1}</td>
                  <td>{faculty.faculty}</td>
                  <td>
                    <button 
                      className="btn btn-danger btn-sm"
                      onClick={() => deleteFaculty(faculty.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ManageFaculty;
