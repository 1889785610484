import React, { useState, useEffect } from 'react';
import apiClient from '../apiClient';

const MoneyStatics = () => {
  const [amount, setAmount] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    // Calculate the start date (30 days ago)
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    const formattedStartDate = thirtyDaysAgo.toISOString().split('T')[0];

    // Set the initial start date and end date states
    setStartDate(formattedStartDate);
    setEndDate(new Date().toISOString().split('T')[0]);

    // Fetch data for the initial date range
    fetchData(formattedStartDate, new Date().toISOString().split('T')[0]);
  }, []);

  const fetchData = async (start, end) => {
    try {
      const response = await apiClient.get(`/api/total-amount-by-date-range/?start_date=${start}&end_date=${end}`);
      setAmount(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleDateChange = (e, type) => {
    const value = e.target.value;
    if (type === 'start') {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
    // Fetch data after selecting dates
    fetchData(startDate, endDate);
  };

  return (
    <div>
      <div className="box mt-3">
        <div className="row d-block">
          <div className="col-auto">
            <h5>Tasks</h5>
          </div>
          <div className="col-auto d-flex justify-content-between">
            <div className="col">
              <input className='form-control' type="date" value={startDate} onChange={(e) => handleDateChange(e, 'start')} />
            </div>
            <div className="col d-flex justify-content-between align-items-center">
                <span className='text-center mx-2'>to</span>
            </div>

            <div className="col">
              <input type="date" className='form-control' value={endDate} onChange={(e) => handleDateChange(e, 'end')} />
            </div>
          </div>
        </div>
        <hr />
        {amount && (
          <div>
            <div className="row">
                <span><span style={{fontWeight:'600'}}>Total Income:</span> Nrs.{amount.billing_total}</span>
            </div>
            <div className="row">
            <span><span style={{fontWeight:'600'}}>Total Income Source:</span> Nrs.{parseInt(amount.course_selected_total)}</span>
            </div>
            <div className="row">
                <span><span style={{fontWeight:'600'}}>Collect Remains:</span> Nrs.{amount.course_selected_total - amount.billing_total}</span>
            </div>
            <div className="row">
                <span><span style={{fontWeight:'600'}}>Total New Users:</span> {amount.active_users_count }</span>
            </div>
            <div className="row">
                <span><span style={{fontWeight:'600'}}>Left Users:</span> {amount.deactive_users_count }</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MoneyStatics;
