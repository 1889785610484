import React, { useState, useEffect } from 'react';
import Navbar from '../Navbars/Navbar';
import Sidebar from '../Navbars/Sidebar';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Table } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import apiClient from '../apiClient';
import { Link } from 'react-router-dom'; 
import { FaHome } from "react-icons/fa";

const Testimonials = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [showModal, setShowModal] = useState(false); 
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [image, setImage] = useState('');
  const [imagePreview, setImagePreview] = useState('');
  const [testimonials, setTestimonials] = useState([]);
  const [selectedTestimonialId, setSelectedTestimonialId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const fetchTestimonials = async () => {
    try {
      const response = await apiClient.get('api/message/');
      setTestimonials(response.data);
    } catch (error) {
      console.error('Error fetching testimonials:', error);
      toast.error('Error fetching testimonials');
    }
  };
  
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append('name', name);
    formData.append('message', message);
    formData.append('image', image);
  
    try {
      const response = await apiClient.post('api/testimonial/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      if (response.status >= 200 && response.status < 300) {
        console.log('Testimonial added successfully');
        toast.success('Testimonial added successfully');
        closeModal();
        fetchTestimonials();
      } else {
        console.error('Failed to add testimonial');
        toast.error('Failed to add testimonial');
      }
    } catch (error) {
      console.error('Error adding testimonial:', error);
      toast.error('Error adding testimonial');
    }
  };

  const handleRemove = (testimonialId) => {
    setSelectedTestimonialId(testimonialId);
    setShowDeleteModal(true);
  };

  const handleConfirmRemove = async () => {
    try {
      const response = await apiClient.delete(`api/testimonial/${selectedTestimonialId}/`);
      if (response.status === 204) {
        console.log('Testimonial deleted successfully');
        toast.success('Testimonial deleted successfully');
        setShowDeleteModal(false);
        fetchTestimonials();
      } else {
        console.error('Failed to delete testimonial');
        toast.error('Failed to delete testimonial');
      }
    } catch (error) {
      console.error('Error deleting testimonial:', error);
      toast.error('Error deleting testimonial');
    }
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  return (
    <div className="main">
      <Sidebar isOpen={isSidebarOpen} />
      <div className="content">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="container-fluid px-4 py-3">
            
          <div className="row d-flex justify-content-between">
            <div className="col-auto">
              <h1>Testimonials</h1>
            </div>
            <div className="col-auto d-flex">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/" className="text-dark" style={{ textDecoration: 'none' }}>
                      <FaHome />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/site" className="text-dark" style={{ textDecoration: 'none' }}>
                      Site
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Testimonials</li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="row d-flex justify-content-end">
          <div className="col-auto">
              <button className='btn btn-success btn-sm' onClick={openModal}>Add Testimonial</button>
            </div>
          </div>
          <div className="row mt-3 px-3">
            <div className="box">
              <Table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Message</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {testimonials.map((testimonial, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{testimonial.name}</td>
                      <td>{testimonial.message}</td>
                      <td>
                        <button className='btn btn-sm btn-danger' onClick={() => handleRemove(testimonial.id)}>Remove</button>
                      </td> 
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
          {/* Modal */}
          <Modal show={showModal} onHide={closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>Add Testimonial</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="col-12">
                  <label htmlFor="name" className='form-label mb-3'>Name</label>
                  <input type="text" className='form-control' value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div className="col-12">
                  <label htmlFor="message" className='form-label mb-3'>Message</label>
                  <textarea name="message" id="message" cols="30" rows="4" className='form-control' value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                </div>
                <div className="col-12">
                  <label htmlFor="image" className='form-label mb-3'>Image</label>
                  <input type="file" className='form-control' onChange={handleImageChange} />
                  {imagePreview && <img src={imagePreview} alt="Preview" className="img-thumbnail mt-2" style={{ width: '100px' }} />}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={closeModal}>Discard</Button>
                <Button variant="success" type="submit">Save</Button>
              </Modal.Footer>
            </form>
          </Modal>
          <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Removal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to remove this testimonial?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
              <Button variant="danger" onClick={handleConfirmRemove}>Remove</Button>
            </Modal.Footer>
          </Modal>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
