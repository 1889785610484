import React, { useState } from 'react';
import Navbar from '../Navbars/Navbar';
import Sidebar from '../Navbars/Sidebar';
import { ToastContainer } from 'react-toastify';
import Modal from 'react-bootstrap/Modal'; 
import SendMessage from './SendMessage';
import GetMessageHistroy from './GetMessageHistroy';

const Messages = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [showModal, setShowModal] = useState(false); 

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSendMessageClick = () => {
    setShowModal(true); 
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="main">
      <Sidebar isOpen={isSidebarOpen} />
      <div className="content">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="container-fluid px-4 py-3">
            <div className="row d-flex justify-content-between my-3">
                <div className="col-auto">
                    <h1>Messages</h1>
                </div>
                <div className="col-auto">
                    <button onClick={handleSendMessageClick} className='btn btn-sm btn-primary'>Send Message</button>
                </div>
            </div>
          <div className="box">
          <GetMessageHistroy/>
          </div>
          
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} backdrop='static' size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Send Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <SendMessage handleCloseModal={handleCloseModal} />        
        </Modal.Body>
      </Modal>
    
      <ToastContainer />

    </div>

  );
};
export default Messages;
