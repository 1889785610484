import React, { useState, useEffect } from 'react';
import apiClient from '../apiClient';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';

const EditCourse = ({ courseId, onSuccess }) => {
  const [courseData, setCourseData] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const response = await apiClient.get(`api/courses/${courseId}/`);
        setCourseData({
          ...response.data,
          title: capitalizeEachWord(response.data.title),
          duration: capitalizeEachWord(response.data.duration)
        });
      } catch (error) {
        console.error('Error fetching course data:', error);
      }
    };

    fetchCourseData();
  }, [courseId]);

  const handleDescriptionChange = (event, editor) => {
    const data = editor.getData();
    setCourseData({ ...courseData, description: data });
  };

  const handleUpdate = async () => {
    setIsUpdating(true);
    try {
      const formData = {
        title: capitalizeEachWord(courseData.title),
        duration: capitalizeEachWord(courseData.duration),
        display: courseData.display,
        description: courseData.description
      };

      await apiClient.put(`/api/courses/update/${courseId}/`, formData);

      if (onSuccess) onSuccess();
    } catch (error) {
      toast.error('Error updating course data!');
      console.error('Error updating course data:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  const capitalizeEachWord = (str) => {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  if (!courseData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <form>
        <div className="col-12 mb-2">
          <label htmlFor="title" className='form-label'>Course Title</label>
          <input 
            type="text" 
            className='form-control' 
            value={courseData.title} 
            onChange={(e) => setCourseData({ ...courseData, title: e.target.value })} 
          />
        </div>
        <div className="row">
          <div className="col-6">
            <label htmlFor="duration" className='form-label'>Course Duration</label>
            <input 
              type="text" 
              className='form-control' 
              value={courseData.duration} 
              onChange={(e) => setCourseData({ ...courseData, duration: e.target.value })} 
            />
          </div>
          <div className="col-6">
            <label htmlFor="display" className='form-label'>Visibility</label> <br />
            <select 
              name="display" 
              id="display" 
              className='form-control' 
              value={courseData.display}
              onChange={(e) => setCourseData({ ...courseData, display: e.target.value })}
            >
              <option value={false}>Private</option>
              <option value={true}>Public</option>
            </select>
          </div>
        </div>

        <div className="col-12">
          <label htmlFor="description" className='form-label'>Description</label>
          <CKEditor
            editor={ClassicEditor}
            data={courseData.description}
            onChange={handleDescriptionChange}
          />
        </div>
        <div className="row d-flex justify-content-end mt-3">
          <div className="col-auto">
            <button 
              type="button" 
              className='btn btn-success' 
              onClick={handleUpdate}
              disabled={isUpdating}
            >
              {isUpdating ? 'Updating...' : 'Update'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditCourse;
