import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import apiClient from '../apiClient';
import { toast } from 'react-toastify';

const Downloads = ({ handleCloseModal }) => {
    const [formData, setFormData] = useState({
        title: '',
        file: null,
        image: null,
        link: '',
    });

    const [description, setDescription] = useState('');

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (event) => {
        const { name, files } = event.target;
        setFormData({ ...formData, [name]: files[0] });
    };

    const handleEditorChange = (event, editor) => {
        const description = editor.getData();
        setDescription(description);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('title', formData.title);
            if (formData.file) {
                formDataToSend.append('file', formData.file);
            }
            if (formData.image) {
                formDataToSend.append('image', formData.image);
            }
            formDataToSend.append('link', formData.link);
            formDataToSend.append('description', description);
    
            const response = await apiClient.post('/api/resources/', formDataToSend);
            if (response.status === 201) {
                toast.success(response.data.message);
                setFormData({
                    title: '',
                    file: null,
                    image: null,
                    link: '',
                });
                setDescription('');
                handleCloseModal(); // Close modal upon successful submission
            } else {
                throw new Error('Failed to add resource');
            }
        } catch (error) {
            console.error('Error adding resource:', error);
            const errorMessage = error.response ? error.response.data.message : 'An error occurred';
            toast.error(errorMessage);
        }
    };
    
    return (
        <form onSubmit={handleSubmit}>
            {/* Form fields */}
            {/* Title */}
            <div className="row">
                <div className="col-12">
                    <label htmlFor="title" className='form-label'>Title</label>
                    <input type="text" className='form-control mb-3' name="title" onChange={handleChange} value={formData.title} />
                </div>
            </div>
            
            {/* Download File */}
            <div className="row">
                <div className="col-6">
                    <label htmlFor="file" className='form-label'>Download File</label>
                    <input type="file" className='form-control mb-3' name="file" onChange={handleFileChange} />
                </div>
                {/* Download Image */}
                <div className="col-6">
                    <label htmlFor="image" className='form-label'>Download Image</label>
                    <input type="file" className='form-control mb-3' name="image" onChange={handleFileChange} accept='image/*' />
                </div>
            </div>

            {/* Download Link */}
            <div className="row">
                <div className="col-12">
                    <label htmlFor="link" className='form-label'>Download Link</label>
                    <input type="text" className='form-control mb-3' name="link" onChange={handleChange} value={formData.link} />
                </div>
            </div>
            
            {/* Description */}
            <div className="row">
                <div className="col-12">
                    <label htmlFor="description" className='form-label'>Description</label>
                    <CKEditor
                        editor={ClassicEditor}
                        onChange={handleEditorChange}
                    />
                </div>
            </div>

            {/* Submit Button */}
            <div className="row d-flex justify-content-end mt-3">
                <div className="col-auto">
                    <button type="submit" className="btn btn-primary">Add Download File</button>
                </div>
            </div>
        </form>
    );
};

export default Downloads;
