import React, { useState, useEffect } from 'react';
import Navbar from '../Navbars/Navbar';
import Sidebar from '../Navbars/Sidebar';
import Table from 'react-bootstrap/Table'; 
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import apiClient from '../apiClient';
import UpdateStudentUserProfile from '../Users/UpdateStudentUserProfile';
import { Tabs, Tab } from 'react-bootstrap';
import AddCourseToNewUser from '../Users/AddCourseToNewUser';
import { ToastContainer, toast } from 'react-toastify';

const Requests = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const fetchData = async () => {
    try {
      const response = await apiClient.get('api/inactive-users/');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (searchQuery.trim() !== '') {
      const filtered = users.filter(user =>
        user.phone.includes(searchQuery) || user.full_name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers([]);
    }
  }, [searchQuery, users]);

  const handleVerify = (user) => {
    setSelectedUser(user);
    setShowVerifyModal(true);
  };

  const handleRemove = (user) => {
    setSelectedUser(user);
    setShowRemoveModal(true);
  };

  const handleCloseVerifyModal = () => {
    setShowVerifyModal(false);
    setSelectedUser(null);
  };

  const handleCloseRemoveModal = () => {
    setShowRemoveModal(false);
    setSelectedUser(null);
  };

  const handleConfirmRemove = async () => {
    try {
      await apiClient.delete(`api/users/${selectedUser.id}/delete/`);
      setShowVerifyModal(false);
      setSelectedUser(null);
      toast.success('User Removed Successfully.');
      fetchData();
      handleCloseRemoveModal(false);
    } catch (error) {
      console.error('Error deleting a user:', error);
      toast.error('Error deleting a user:');
    }
  };

  const handleConfirmVerify = async () => {
    try {
      await apiClient.post(`api/users/${selectedUser.id}/activate/`, { is_active: 'true' });
      console.log('User verified:', selectedUser);
      setShowVerifyModal(false);
      setSelectedUser(null);
      fetchData(); 
      toast.success('User Verified Successfully.');
    } catch (error) {
      console.error('Error verifying user:', error);
      toast.error('Error verifying user.');
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const capitalizeTitle = (title) => 
    title.replace(/\b\w/g, char => char.toUpperCase());

  const lowerCase = (title) => title.toLowerCase();
  return (
    <div className="main">
      <Sidebar isOpen={isSidebarOpen} />
      <div className="content">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="container-fluid px-4 py-3">
          <div className="row d-flex justify-content-between">
            <div className="col-auto">
              <h1>User Requests</h1>
            </div>
            <div className="col-auto">
              <div className="row d-flex justify-content-end align-items-center">
                <div className="col-auto">
                  <label htmlFor="search">Search User</label>
                </div>
                <div className="col-auto">
                  <input type="text" className='form-control' placeholder='Search by phone number or name' value={searchQuery} onChange={handleSearchChange} />
                </div>
              </div>
            </div>
          </div>
          <div className="box mt-3">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th className='text-center'>S.N.</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Username</th>
                  <th>Address</th>
                  <th>Phone</th>
                  <th className='text-center'>Requested At</th>
                  <th className='text-center'>Action</th>
                </tr>
              </thead>
              <tbody>
                {(searchQuery.trim() === '' ? users : filteredUsers).map((user, index) => (
                  <tr key={user.id}>
                    <td className='text-center'>{index + 1}</td>
                    <td>{capitalizeTitle(user.full_name)}</td>
                    <td>{lowerCase(user.email)}</td>
                    <td>{lowerCase(user.username)}</td>
                    <td>{capitalizeTitle(user.address)}</td>
                    <td>{user.phone}</td>
                    <td className='text-center'>{formatDate(user.created_at)}</td>
                    <td className='text-center'>
                      <button className='btn btn-sm btn-success mx-1' onClick={() => handleVerify(user)}>Verify</button>
                      <button className='btn btn-sm btn-danger mx-1' onClick={() => handleRemove(user)}>Remove</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <Modal show={showVerifyModal} onHide={handleCloseVerifyModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedUser && (
              <div>
                <p>{selectedUser.full_name}?</p>
              </div>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedUser && (
            <Tabs defaultActiveKey="tab1">
              <Tab eventKey="tab1" title="Personal Information">
                <UpdateStudentUserProfile selectedUserId={selectedUser.id} />
              </Tab>
              <Tab eventKey="tab2" title="Course Information">
              <AddCourseToNewUser selectedUserId={selectedUser.id}/>
              </Tab>
            </Tabs>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseVerifyModal}>Cancel</Button>
          <Button variant="success" onClick={handleConfirmVerify}>Verify</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showRemoveModal} onHide={handleCloseRemoveModal}>
        <Modal.Header closeButton>
          <Modal.Title>Removal Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedUser && (
            <div>
              <p>Are you sure you want to remove {selectedUser.full_name}?</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseRemoveModal}>Cancel</Button>
          <Button variant="danger" onClick={handleConfirmRemove}>Remove</Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />

    </div>

  );
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString(); 
};

export default Requests;
