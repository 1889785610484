import React, { useState, useEffect } from 'react';
import apiClient from '../apiClient';
import { toast } from 'react-toastify';
const ListStaff = () => {
  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    const fetchStaff = async () => {
      try {
        const response = await apiClient.get('/api/staff-list/');
        setStaffList(response.data);
      } catch (error) {
        console.error('Error fetching staff:', error);
      }
    };

    fetchStaff();
  }, []);

  const handleDelete = async (id) => {
    try {
      await apiClient.post(`/api/remove-staff/${id}/`);
      setStaffList(staffList.filter(staff => staff.id !== id));
      toast.success("Staff removed successfully.");
    } catch (error) {
      console.error('Error deleting staff:', error);
    }
  };

  return (
    <div className="container">
      <h5>List of Staff</h5>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Username</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {staffList.map((staff, index) => (
            <tr key={index}>
              <td>{staff.name}</td>
              <td>{staff.email}</td>
              <td>{staff.phone}</td>
              <td>{staff.username}</td>
              <td>
                <button className="btn btn-danger btn-sm" onClick={() => handleDelete(staff.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListStaff;
