import React, { useState, useEffect } from 'react';
import Navbar from '../Navbars/Navbar';
import Sidebar from '../Navbars/Sidebar';
import apiClient from '../apiClient';
import Table from 'react-bootstrap/Table'; 
import { BiReset } from 'react-icons/bi'; 
import { Link } from 'react-router-dom';

const Billing = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [allBills, setAllBills] = useState([]);
  const [filteredBills, setFilteredBills] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    fetchAllBills();
  }, []);

  const fetchAllBills = () => {
    apiClient.get('api/last20bills/')  
      .then(response => {
        setAllBills(response.data);
        setFilteredBills(response.data.slice(0, 20)); 
      })
      .catch(error => console.error('Error fetching bills:', error));
  };

  const filterBills = () => {
    let filtered = allBills;
    if (!startDate && !endDate && !phoneNumber) {
      filtered = allBills.slice(0, 20);
    } else {
      filtered = allBills.filter(bill => {
        const billDate = new Date(bill.date);
        return (!startDate || billDate >= new Date(startDate)) &&
               (!endDate || billDate <= new Date(endDate)) &&
               (!phoneNumber || bill.user.phone.includes(phoneNumber));
      });
    }
    setFilteredBills(filtered);
  };
  
  const resetFilters = () => {
    setStartDate('');
    setEndDate('');
    setPhoneNumber('');
    setFilteredBills(allBills.slice(0, 20)); 
  };
  const capitalizeTitle = (title) => 
    title.replace(/\b\w/g, char => char.toUpperCase());
  return (
    <div className="main">
      <Sidebar isOpen={isSidebarOpen} />
      <div className="content">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="container-fluid px-4 py-3">
          <div className="row">
            <h1>Billing</h1>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="from">From</label>
                <input type="date" className='form-control' value={startDate} onChange={e => setStartDate(e.target.value)} />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="to">To</label>
                <input type="date" className='form-control' value={endDate} onChange={e => setEndDate(e.target.value)} />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="search">Search by Phone</label>
                <input type="text" className='form-control' placeholder='Search with phonenumber' value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
              </div>
            </div>
            <div className="col d-flex align-items-center">
                <button className="btn btn-primary me-2" onClick={filterBills}>Filter</button>
                <button className="btn btn-secondary me-2" onClick={resetFilters}><BiReset /> Reset</button>
                <Link className='btn btn-success' to="/billing/due-payment-users">List Due Users</Link>
            </div>
          </div>
          <div className="box">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Invoice Number</th>
                  <th className='text-center'>Date</th>
                  <th>Name</th>
                  <th className='text-center'>Amount</th>
                  <th className='text-center'>Discount</th>
                  <th className='text-center'>Reception</th>
                </tr>
              </thead>
              <tbody>
                {filteredBills.length > 0 ? (
                    filteredBills.map((bill, index) => (
                    <tr key={bill.invoice_number}>
                        <td className='text-center'>{index + 1}</td>
                        <td className='text-center'>{bill.invoice_number}</td>
                        <td className='text-center'>
                        {new Date(bill.date).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}
                        {' '}
                        {new Date(bill.date).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                        </td>
                        <td>{capitalizeTitle(bill.user.full_name)}</td> 
                        <td style={{ textAlign: 'right' }}>{bill.amount}</td>
                        <td style={{ textAlign: 'right' }}>{bill.discount}</td>
                        <td className='text-center'>{bill.reception}</td>
                    </tr>
                    ))
                ) : (
                    <tr>
                    <td colSpan="7" className="text-center">No records found</td>
                    </tr>
                )}
            </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Billing;
