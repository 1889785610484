import React from 'react';
import AddShift from './AddShift';
import ShiftList from './ShiftList';
import { ToastContainer } from 'react-toastify';

const Shifts = () => {
  const [refreshKey, setRefreshKey] = React.useState(0);

  const handleShiftAdded = () => {
    setRefreshKey(oldKey => oldKey + 1);
  };

  return (
    <>
      <AddShift onShiftAdded={handleShiftAdded} />
      <ShiftList key={refreshKey} />
      <ToastContainer/>
    </>
  );
}

export default Shifts;
