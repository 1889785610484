import React, { useState, useEffect } from 'react';
import apiClient from '../apiClient';

const AddCourseToNewUser = ({ selectedUserId }) => {
  const [faculties, setFaculties] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [courses, setCourses] = useState([]);
  const [shifts, setShift] = useState([]);
  const [userCourses, setUserCourses] = useState([]);

  useEffect(() => {
    apiClient.get('api/faculties/')
      .then(response => {
        setFaculties(response.data);
      })
      .catch(error => {
        console.error('Error fetching faculties:', error);
      });
  }, []);

  useEffect(() => {
    if (selectedFaculty) {
      apiClient.get(`api/courses/${selectedFaculty}/filter/`)
        .then(response => {
          setCourses(response.data.courses);
        })
        .catch(error => {
          console.error('Error fetching courses:', error);
        });
    }
  }, [selectedFaculty]);

  useEffect(() => {
    if(selectedCourse){
      apiClient.get(`api/shift/${selectedCourse}/filter/`)
      .then(response => {
        setShift(response.data.shift);
      })
      .catch(error => {
        console.error('error fetching shifts:', error);
      })
    }
  }, [selectedCourse]);

  useEffect(() => {
    if (selectedUserId) {
      apiClient.get(`api/get-user-course-selected/${selectedUserId}/`)
        .then(response => {
          setUserCourses(response.data);
        })
        .catch(error => {
          console.error('Error fetching user courses:', error);
        });
    }
  }, [selectedUserId]);

  const fetchUserCourses = () => {
    if (selectedUserId) {
      apiClient.get(`api/get-user-course-selected/${selectedUserId}/`)
        .then(response => {
          setUserCourses(response.data);
        })
        .catch(error => {
          console.error('Error fetching user courses:', error);
        });
    }
  }

  const handleAddCourseInfo = (event) => {
    event.preventDefault();
  
    const formData = {
      course: parseInt(selectedCourse),
      shift: event.target.shift.value,
      user: selectedUserId
    }
    
    apiClient.post('api/add-selected-course/', formData)
    .then(response => {
      console.log('User course added successfully', response.data);
      setSelectedCourse('');
      setSelectedFaculty('');
      fetchUserCourses();
    })
    .catch(error => {
      console.error('error creating course and shift', error)
    })
  }

  const handleRemoveCourse = (courseId) => {
    apiClient.delete(`api/delete-course-selected/${courseId}/`)
      .then(response => {
        console.log('Course removed successfully', response.data);
        fetchUserCourses();
      })
      .catch(error => {
        console.error('error removing course', error)
      })
  }

 
  const capitalizeTitle = (title) => 
    title.replace(/\b\w/g, char => char.toUpperCase());
  return (
    <div>
      <form onSubmit={handleAddCourseInfo}>
        <div className="row d-flex my-3 justify-content-between">
          <div className="col">
            <label htmlFor="faculty" className='form-label'>Faculty</label>
            <select name="faculty" id="faculty" className='form-control' onChange={(e) => setSelectedFaculty(e.target.value)}>
                <option value="">Select Faculty</option>
                {faculties.map(faculty => (
                  <option key={faculty.id} value={faculty.id}>{capitalizeTitle(faculty.faculty)}</option>
                ))}
              </select>
          </div>
          <div className="col">
            <label htmlFor="course" className='form-label'>Course</label>
            <select id="course" className='form-control' onChange={(e) => setSelectedCourse(e.target.value)}>
              <option value="">Select Course</option>
              {Array.isArray(courses) && courses.map(course => (
                  <option key={course.id} value={course.id}>{capitalizeTitle(course.title)}</option>
                ))}
            </select>
          </div>
          <div className="col">
            <label htmlFor="shift" className='form-label'>Shift</label>
            <select name="shift" id="shift" className='form-control'>
              <option value="">Select Shift</option>
              {Array.isArray(shifts) && shifts.map(course => (
                  <option key={course.id} value={course.id}>{capitalizeTitle(course.shiftname)}</option>
                ))}
            </select>
          </div>
          <div className="col d-flex align-items-end">
            <button className='btn btn-primary'>Add Course</button>
          </div>
        </div>
      </form>
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Course</th>
            <th>Shift</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
  {userCourses.map((course, index) => (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>{capitalizeTitle(course.course.title)}</td>
      <td>{capitalizeTitle(course.shift.shiftname)}</td>
      <td>
        <button className='btn btn-sm btn-danger' onClick={() => handleRemoveCourse(course.id)}>Remove</button> {/* Pass the course ID to the handleRemoveCourse function */}
      </td>
    </tr>
  ))}
</tbody>

      </table>
    </div>
  );
};

export default AddCourseToNewUser;
