import React, { useState, useEffect } from 'react';
import Navbar from '../Navbars/Navbar';
import Sidebar from '../Navbars/Sidebar';
import { Link } from 'react-router-dom'; 
import { Table, Modal, Button } from 'react-bootstrap'; 
import apiClient from '../apiClient';
import { ToastContainer, toast } from 'react-toastify';
import ViewQuestions from './ViewQuestions';

const Questions = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [setname, setSetName] = useState('');
  const [courseId, setCourseId] = useState('');
  const [questionSets, setQuestionSets] = useState([]);
  const [showModal, setShowModal] = useState(false); 
  const [showQuestionsModal, setShowQuestionsModal] = useState(false);
  const [selectedSetId, setSelectedSetId] = useState(null); 
  const [faculties, setFaculties] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState('');
  const [courses, setCourses] = useState([]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSetCreation = () => {
    const formData = {
      setname: setname,
      course: courseId
    };
  
    apiClient.post('api/question-set/', formData)
      .then(response => {
        toast.success('Set created successfully', response.data);
        setSetName('');
        setCourseId('');
        fetchQuestionSets(); // Fetch the updated list of question sets
      })
      .catch(error => {
        console.error('Error creating set:', error);
        toast.error('Error creating set:', error);
      });
  };

  const fetchQuestionSets = () => {
    apiClient.get('api/question-set/')
      .then(response => {
        console.log('Question sets fetched successfully:', response.data);
        setQuestionSets(response.data);
      })
      .catch(error => {
        console.error('Error fetching question sets:', error);
      });
  };

  useEffect(() => {
    fetchQuestionSets();
  }, []); 

  const handleOpenModal = (setId) => {
    setSelectedSetId(setId);
    setShowModal(true);
  };

  const ViewQuestionsModal = (setId) => {
    setSelectedSetId(setId);
    setShowQuestionsModal(true);
  }

  const handleCloseModal = () => {
    setSelectedSetId(null);
    setShowModal(false);
    setShowQuestionsModal(false);
  };

  useEffect(() => {
    const fetchFaculties = async () => {
      try {
        const response = await apiClient.get('api/faculties/');
        setFaculties(response.data);
      } catch (error) {
        console.error('Error fetching faculties:', error);
      }
    };

    fetchFaculties(); 
  }, []); 

  const handleFacultyChange = (e) => {
    setSelectedFaculty(e.target.value);
  };

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        if (selectedFaculty) {
          const response = await apiClient.get(`/api/courses/${selectedFaculty}/filter/`);
          setCourses(response.data.courses);
        }
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, [selectedFaculty]);

  const handleDeleteSet = () => {
    if (selectedSetId) {
      apiClient.delete(`api/question-set/${selectedSetId}/`)
        .then(response => {
          console.log('Set deleted successfully');
          toast.success('Set deleted successfully');
          handleCloseModal();
          fetchQuestionSets(); // Fetch the updated list of question sets
        })
        .catch(error => {
          console.error('Error deleting set:', error);
          toast.error('Error deleting set:', error);
        });
    }
  };

  return (
    <div className="main">
      <Sidebar isOpen={isSidebarOpen} />
      <div className="content">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="container-fluid px-4 py-3">
          <div className="row d-flex align-items-center justify-content-between">
            <div className="col-auto">
              <h1>Questions</h1>
            </div>
            <div className="col-auto">
              <Link className='btn btn-success' to={'/questions/add-question'}>Add Question</Link>
            </div>
          </div>

          <div className="box mt-3">
            <div className="row d-flex align-items-center px-3">
              <div className="col">
                <label htmlFor="selectFaculty" className='mb-2 mt-2'>Select Faculty</label>
                <select name="selectFaculty" id="selectFaculty" className='form-control' value={selectedFaculty} onChange={handleFacultyChange} required>
                  <option value="">Select Faculty</option>
                  {faculties.map(faculty => (
                    <option key={faculty.id} value={faculty.id}>
                      {capitalizeEachWord(faculty.faculty)}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col">
                <label htmlFor="selectCourse" className='form-label mb-3'>Select Course</label>
                <select name="selectCourse" id="selectCourse" className='form-control' value={courseId} onChange={(e) => setCourseId(e.target.value)} required>
                  <option value="">Select Course</option>
                  {courses && Array.isArray(courses) && courses.map(course => (
                    <option key={course.id} value={course.id}>{course.title}</option>
                  ))}
                </select>
              </div>
              <div className="col">
                <label htmlFor="createset" className='mb-2 mt-2'>Create Set</label>
                <input type="text" className='form-control' value={setname} onChange={(e) => setSetName(e.target.value)} placeholder='Set Name' required/>
              </div>
              <div className="col-auto align-self-end">
                <button className='btn btn-primary' onClick={handleSetCreation}>Add Set</button>
              </div>
            </div>

            <div className="row px-3 mt-3">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Set</th>
                    <th className='text-center'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {questionSets.map((set, index) => (
                    <tr key={set.id}>
                      <td>{index + 1}</td>
                      <td>{set.setname.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</td>
                      <td className='text-center'>
                        <button className='btn btn-success btn-sm mx-1' onClick={() => ViewQuestionsModal(set.id)}>View Questions</button>
                        <button className='btn btn-sm btn-danger mx-1' onClick={() => handleOpenModal(set.id)}>Delete Set</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Set</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this set?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteSet}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showQuestionsModal} onHide={handleCloseModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Questions</Modal.Title>
        </Modal.Header>
        <Modal.Body><ViewQuestions selectedSetId={selectedSetId} /></Modal.Body>
      </Modal>
      <ToastContainer />
    </div>
  );
}

function capitalizeEachWord(str) {
  return str.replace(/\b\w/g, c => c.toUpperCase());
}

export default Questions;
