import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Sidebar.css';
import expandedLogo from '../../goldenlogowhite.webp';
import collapsedLogo from '../../logo.webp';
import { PiStudentBold } from "react-icons/pi";
import { FaUser, FaClipboard, FaMoneyCheckAlt } from "react-icons/fa";
import { BiSolidDashboard } from "react-icons/bi";
import { IoBookSharp, IoGitPullRequestSharp } from "react-icons/io5";
import { IoIosSettings } from "react-icons/io";
import { FiMessageSquare } from "react-icons/fi";

const Sidebar = ({ isOpen }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const location = useLocation();

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    const isActive = (path) => {
        return location.pathname.startsWith(path);
    };

    return (
        <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
            <div className="sidebar-header">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="brand">
                        <img src={isOpen ? expandedLogo : collapsedLogo} alt="Logo" className="brand-logo" />
                    </div>
                    <button className="sidebar-toggle-btn" onClick={toggleExpansion}>
                        <i className="fas fa-bars"></i>
                    </button>
                </div>
            </div>
            <div className="sidebar-content">
                <ul className="sidebar-menu">
                    <Link to="/dashboard">
                        <li className={`sidebar-menu-item ${isActive('/dashboard') ? 'active' : ''}`}>
                            <BiSolidDashboard className='sidebar-icon' />
                            <span className="sidebar-menu-item-name">Dashboard</span>
                        </li>
                    </Link>

                    <hr className='sidebar-divider'/>
                    <div className='sidebar-heading'>Main</div>

                    <Link to="/students">
                        <li className={`sidebar-menu-item ${isActive('/students') ? 'active' : ''}`}>
                            <PiStudentBold className='sidebar-icon' />
                            <span className="sidebar-menu-item-name">Students</span>
                        </li>
                    </Link>
                    <Link to="/users">
                        <li className={`sidebar-menu-item ${isActive('/users') ? 'active' : ''}`}>
                            <FaUser className='sidebar-icon' />
                            <span className="sidebar-menu-item-name">Users</span>
                        </li>
                    </Link>

                    <hr className='sidebar-divider'/>
                    <div className='sidebar-heading'>Courses</div>

                    <Link to="/courses">
                        <li className={`sidebar-menu-item ${isActive('/courses') ? 'active' : ''}`}>
                            <IoBookSharp className='sidebar-icon' />
                            <span className="sidebar-menu-item-name">Courses</span>
                        </li>
                    </Link>
                    <Link to="/questions">
                        <li className={`sidebar-menu-item ${isActive('/questions') ? 'active' : ''}`}>
                            <FaClipboard className='sidebar-icon' />
                            <span className="sidebar-menu-item-name">Questions</span>
                        </li>
                    </Link>
                    <Link to="/schedule-exams">
                        <li className={`sidebar-menu-item ${isActive('/schedule-exams') ? 'active' : ''}`}>
                            <IoBookSharp className='sidebar-icon' />
                            <span className="sidebar-menu-item-name">Schedule Exam</span>
                        </li>
                    </Link>
                    <hr className='sidebar-divider'/>
                    <div className='sidebar-heading'>Channels</div>
                    
                    <Link to="/site">
                        <li className={`sidebar-menu-item ${isActive('/site') ? 'active' : ''}`}>
                            <FaMoneyCheckAlt className='sidebar-icon' />
                            <span className="sidebar-menu-item-name">Site</span>
                        </li>
                    </Link>

                    <Link to="/billing">
                        <li className={`sidebar-menu-item ${isActive('/billing') ? 'active' : ''}`}>
                            <FaMoneyCheckAlt className='sidebar-icon' />
                            <span className="sidebar-menu-item-name">Billing</span>
                        </li>
                    </Link>
                    <Link to="/requests">
                        <li className={`sidebar-menu-item ${isActive('/requests') ? 'active' : ''}`}>
                            <IoGitPullRequestSharp className='sidebar-icon' />
                            <span className="sidebar-menu-item-name">Requests</span>
                        </li>
                    </Link>
                    <Link to="/settings">
                        <li className={`sidebar-menu-item ${isActive('/settings') ? 'active' : ''}`}>
                            <IoIosSettings className='sidebar-icon' />
                            <span className="sidebar-menu-item-name">Settings</span>
                        </li>
                    </Link>
                    <Link to="/messages">
                        <li className={`sidebar-menu-item ${isActive('/messages') ? 'active' : ''}`}>
                            <FiMessageSquare  className='sidebar-icon' />
                            <span className="sidebar-menu-item-name">Messages</span>
                        </li>
                    </Link>

                </ul>
            </div>
        </div>
    );
};

export default Sidebar;
