import React, { useState, useEffect } from 'react';
import Navbar from '../Navbars/Navbar';
import Sidebar from '../Navbars/Sidebar';
import { Link, useNavigate } from 'react-router-dom';
import { FaHome } from "react-icons/fa";
import apiClient from '../apiClient';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { useToast } from '../ToastContext/ToastContext';
import 'react-toastify/dist/ReactToastify.css';

const AddCourses = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [facultyOptions, setFacultyOptions] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState('');
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    cost: '',
    duration: '',
    display: '',
    faculty: '',
    site_list: '',
    image: null,
    imageUrl: null
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setToastMessage } = useToast(); // Use ToastContext

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    apiClient.get('api/faculties/')
      .then(response => setFacultyOptions(response.data))
      .catch(error => console.error('Error fetching faculty options:', error));
  }, []);

  const handleFacultyChange = (event) => {
    setSelectedFaculty(event.target.value);
    setFormData({
      ...formData,
      faculty: event.target.value
    });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setFormData({
        ...formData,
        image: file,
        imageUrl: imageUrl
      });
    } else {
      setFormData({
        ...formData,
        image: null,
        imageUrl: null
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const form = new FormData();
    form.append('title', formData.title);
    form.append('description', formData.description);
    form.append('cost', formData.cost);
    form.append('duration', formData.duration);
    form.append('visibility', formData.display);
    form.append('faculty', formData.faculty);
    form.append('site_list', formData.site_list);
    form.append('image', formData.image);

    apiClient.post('api/courses/create/', form)
      .then(response => {
        if (response.status === 201) {
          setToastMessage('Course created successfully.'); // Set toast message
          setFormData({
            title: '',
            description: '',
            cost: '',
            duration: '',
            display: '',
            faculty: '',
            site_list: '',
            image: null,
            imageUrl: null
          });
          setSelectedFaculty('');
          navigate('/courses', { state: { toastMessage: 'Course created successfully.' } });
        } else {
          throw new Error('Failed to create course.');
        }
      })
      .catch(error => {
        console.error('Error creating course:', error);
        toast.error('Invalid form data.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="main">
      <Sidebar isOpen={isSidebarOpen} />
      <div className="content">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="container-fluid px-4 py-3">
          <div className='row my-2 align-items-center d-block'>
            <div className='col-md-12 d-flex justify-content-between'>
              <h1>Add Course</h1>
              <div className="col-auto">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/" className="text-dark" style={{ textDecoration: 'none' }}>
                        <FaHome />
                      </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/courses" className="text-dark" style={{ textDecoration: 'none' }}>
                        Courses
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Add Course</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
          <div className="box">
            <form onSubmit={handleSubmit}>
              <div className="row d-flex">
                <div className="mb-2 mt-3 col-12">
                  <label htmlFor="title" className='form-label mb-2'>Course Title</label>
                  <input type="text" className='form-control' value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })} required/>
                </div>
                <div className="col-3 mt-3">
                  <div className="mb-3">
                    <label htmlFor="faculty" className='form-label mb-2'>Select Faculty</label>
                    <select className='form-control' value={selectedFaculty} onChange={handleFacultyChange} required>
                      <option value="">Select Faculty</option>
                      {facultyOptions.map(faculty => (
                        <option key={faculty.id} value={faculty.id}>{faculty.faculty}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-3 mt-3">
                  <div className="mb-3">
                    <label htmlFor="courseCost" className='form-label mb-2'>Course Cost</label>
                    <input type="number" className='form-control' value={formData.cost} onChange={(e) => setFormData({ ...formData, cost: e.target.value })} required/>
                  </div>
                </div>
                <div className="col-3 mt-3">
                  <div className="mb-3">
                    <label htmlFor="duration" className='form-label mb-2'>Course Duration</label>
                    <input type="text" className='form-control' value={formData.duration} onChange={(e) => setFormData({ ...formData, duration: e.target.value })} required/>
                  </div>
                </div>
                <div className="col-3 mt-3">
                  <div className="mb-3">
                    <label htmlFor="visibility" className='form-label mb-2'>Visibility</label>
                    <select name="visibility" id="visibility" className='form-control' value={formData.display} onChange={(e) => setFormData({ ...formData, display: e.target.value })} required>
                      <option value="">Select Visibility</option>
                      <option value={true}>Public</option>
                      <option value={false}>Private</option>
                    </select>
                  </div>
                </div>
                <div className="col-8">
                  <label htmlFor="description" className='form-label mb-2'>Description</label>
                  <CKEditor editor={ClassicEditor} data={formData.description} onChange={(event, editor) => {
                    const data = editor.getData();
                    setFormData({ ...formData, description: data });
                  }}/>
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="image" className='form-label mb-2'>Choose Image</label>
                    <input type="file" accept="image/*" className='form-control' onChange={handleImageChange}/>
                    {formData.imageUrl && <img src={formData.imageUrl} alt="Preview" style={{ maxWidth: '100%', marginTop: '10px' }} />}
                </div>
              </div>
              <div className="row d-flex justify-content-end mt-3 mb-3">
                <div className="col-auto">
                  {isSubmitting ? (
                    <button className="btn btn-primary">Submitting...</button>
                  ) : (
                    <button type="submit" className="btn btn-primary">Submit</button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default AddCourses;
