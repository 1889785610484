import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import React, { useEffect, useState } from 'react';
import Navbar from '../Navbars/Navbar';
import Sidebar from '../Navbars/Sidebar';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import apiClient from '../apiClient';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateQuestion = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [faculties, setFaculties] = useState([]);
  const [sets, setSets] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState('');
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [setValue, setSelectedSet] = useState('');
  const [label, setLabel] = useState('');
  const [questionType, setQuestionType] = useState('');
  const [answerType, setAnswerType] = useState('');
  const [questionModal, setQuestionModel] = useState('');
  const [questionNumber, setQuestionNumber] = useState('');
  const [isQuestionExists, setIsQuestionExists] = useState(false);

  //for text
  const [question_text, setQuestionText] = useState('');
  const [option1_text, setOption1Text] = useState('');
  const [option2_text, setOption2Text] = useState('');
  const [option3_text, setOption3Text] = useState('');
  const [option4_text, setOption4Text] = useState('');
    //for image
  const [question_image, setQuestionImage] = useState('');
  const [option1_image, setOption1Image] = useState('');
  const [option2_image, setOption2Image] = useState('');
  const [option3_image, setOption3Image] = useState('');
  const [option4_image, setOption4Image]= useState('');
//for audio
  const [question_audio, setQuestionAudio] = useState('');
  const [option1_audio, setOption1Audio] = useState('');
  const [option2_audio, setOption2Audio] = useState('');
  const [option3_audio, setOption3Audio] = useState('');
  const [option4_audio, setOption4Audio] = useState('');
  const [answer, setAnswer] = useState('');
  
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        const fetchFaculties = async () => {
          try {
            const response = await apiClient.get('api/faculties/');
            setFaculties(response.data);
          } catch (error) {
            console.error('Error fetching faculties:', error);
          }
        };
    
        fetchFaculties(); 
      }, []); 

      const handleFacultyChange = (e) => {
        setSelectedFaculty(e.target.value);
      };

    useEffect(() => {
        const fetchCourses = async () => {
          try {
            if (selectedFaculty) {
              const response = await apiClient.get(`api/courses/${selectedFaculty}/filter/`);
              setCourses(response.data.courses);
            }
          } catch (error) {
            console.error('Error fetching courses:', error);
          }
        };
    
        fetchCourses();
      }, [selectedFaculty]);

      const handleCourseChange = (e) => {
        setSelectedCourse(e.target.value);
      };
      
      useEffect(() => {
        const fetchSet = async () => {
            try{
                if(selectedCourse){
                    const response = await apiClient.get(`api/courses/${selectedCourse}/sets/`);
                    setSets(response.data)
                }
            }catch(error){
                console.error('Error fetching Set', error);
            }
        };
        fetchSet();

      }, [selectedCourse]);

      const handleSelectSet = (e) => {
        setSelectedSet(e.target.value);
        setIsQuestionExists(false);

      }

      const handleQuestionNumberChange = async (e) => { 
        const value = e.target.value;
        setQuestionNumber(value);

        if (setValue && value) {
            try {
                await apiClient.get(`api/check-question/${setValue}/${value}/`);
                setIsQuestionExists(true); 
            } catch (error) {
                setIsQuestionExists(false); 
            }
        }
    };

    const isAddQuestionDisabled = !questionNumber || !setValue || isQuestionExists;

      const handleQuestionTypeChange = (e) => {
        setQuestionType(e.target.value);
      };
      const handleQuestionModal = (e) => {
        setQuestionModel(e.target.value);
      }

      const renderQuestionInput = () => {
        if (questionType === 'text') {
            return (
                <React.Fragment>
                    <label htmlFor="question" className='form-label mb-3'>Question</label>
                    <input type="text" className="form-control" name='question' onChange={(e) => setQuestionText(e.target.value)} />
                </React.Fragment>
            );
        } else if (questionType === 'image') {
            return (
                <React.Fragment>
                    <label htmlFor="question" className='form-label mb-3'>Question</label>
                    <input type="file" accept='image/*' className="form-control" name='question' onChange={(e) => setQuestionImage(e.target.files[0])} />
                </React.Fragment>
            );
        } else if (questionType === 'audio') {
            return (
                <React.Fragment>
                    <label htmlFor="question" className='form-label mb-3'>Question</label>
                    <input type="file" accept="audio/*" className="form-control" name='question' onChange={(e) => setQuestionAudio(e.target.files[0])} />
                </React.Fragment>
            );
        }
        return null;
    };    
      const handleAnswerTypeChange = (e) => {
        setAnswerType(e.target.value);
      }

      const renderAnswerInput = () => {
        if (answerType === 'text') {
            return (
                <React.Fragment>
                    <label htmlFor="answerType" className='form-label mb-3'>Answers</label>
                    <div className="row">
                    <div className="col-3">
                        <label htmlFor="option1" className='form-label'>Option 1</label>
                        <input type="text" className="form-control" name='option1' onChange={(e) => setOption1Text(e.target.value)} />

                    </div>
                    <div className="col-3">
                        <label htmlFor="option2" className='form-label'>Option 2</label>
                        <input type="text" className="form-control" name='option2' onChange={(e) => setOption2Text(e.target.value)} />
                    </div>
                    <div className="col-3">
                        <label htmlFor="option3" className='form-label'>Option 3</label>
                        <input type="text" className="form-control" name='option3' onChange={(e) => setOption3Text(e.target.value)} />
                    </div>
                    <div className="col-3">
                        <label htmlFor="option4" className='form-label'>Option 4</label>
                        <input type="text" className="form-control" name='option4' onChange={(e) => setOption4Text(e.target.value)} />
                    </div>
                    </div>

                </React.Fragment>
                
            );
        } else if (answerType === 'image') {
            return (
                <React.Fragment>
                    <label htmlFor="answerType" className='form-label mb-3'>Answers</label>
                    <div className="row">
                    <div className="col-3">
                        <label htmlFor="option1" className='form-label'>Option 1</label>
                        <input type="file" accept='image/*' className="form-control" name='option1' onChange={(e) => setOption1Image(e.target.files[0])} />

                    </div>
                    <div className="col-3">
                        <label htmlFor="option2" className='form-label'>Option 2</label>
                        <input type="file" accept='image/*' className="form-control" name='option2' onChange={(e) => setOption2Image(e.target.files[0])} />
                    </div>
                    <div className="col-3">
                        <label htmlFor="option3" className='form-label'>Option 3</label>
                        <input type="file" accept='image/*' className="form-control" name='option3' onChange={(e) => setOption3Image(e.target.files[0])} />
                    </div>
                    <div className="col-3">
                        <label htmlFor="option4" className='form-label'>Option 4</label>
                        <input type="file" accept='image/*' className="form-control" name='option4' onChange={(e) => setOption4Image(e.target.files[0])} />
                    </div>
                    </div>

                </React.Fragment>
            );
        }else if(answerType === 'audio'){
            return (
                <React.Fragment>
                    <label htmlFor="answerType" className='form-label mb-3'>Answers</label>
                    <div className="row">
                    <div className="col-3">
                        <label htmlFor="option1" className='form-label'>Option 1</label>
                        <input type="file" accept="audio/*"  className="form-control" name='option1' onChange={(e) => setOption1Audio(e.target.files[0])} />

                    </div>
                    <div className="col-3">
                        <label htmlFor="option2" className='form-label'>Option 2</label>
                        <input type="file" accept="audio/*"  className="form-control" name='option1' onChange={(e) => setOption2Audio(e.target.files[0])} />
                    </div>
                    <div className="col-3">
                        <label htmlFor="option3" className='form-label'>Option 3</label>
                        <input type="file" accept="audio/*"  className="form-control" name='option3' onChange={(e) => setOption3Audio(e.target.files[0])} />
                    </div>
                    <div className="col-3">
                        <label htmlFor="option3" className='form-label'>Option 4</label>
                        <input type="file" accept="audio/*"  className="form-control" name='option4' onChange={(e) => setOption4Audio(e.target.files[0])} />
                    </div>
                    </div>

                </React.Fragment>
            )
        }
        return null;
    };

    
    const handleAnswerChange = (e) => {
        setAnswer(e.target.value);
      };

      const [isSubmitting, setIsSubmitting] = useState(false);
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        const formData = new FormData();
        formData.append('set', setValue);
        formData.append('qn', questionNumber)
        formData.append('question_type', questionType);
        formData.append('answer_type', answerType);
        formData.append('label', label);
        formData.append('answer', answer);
        formData.append('question_model', questionModal);
    
        // Append text data
        formData.append('question_text', question_text);
        formData.append('option1_text', option1_text);
        formData.append('option2_text', option2_text);
        formData.append('option3_text', option3_text);
        formData.append('option4_text', option4_text);
    
        // Append image files
        formData.append('question_image', question_image);
        formData.append('option1_image', option1_image);
        formData.append('option2_image', option2_image);
        formData.append('option3_image', option3_image);
        formData.append('option4_image', option4_image);
    
        // Append audio files
        formData.append('question_audio', question_audio);
        formData.append('option1_audio', option1_audio);
        formData.append('option2_audio', option2_audio);
        formData.append('option3_audio', option3_audio);
        formData.append('option4_audio', option4_audio);
    
        try {
            const response = await apiClient.post('api/questions/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Question created:', response.data);
            setSelectedFaculty('');
            setSelectedCourse('');
            setSelectedSet('');
            setQuestionNumber('');
            setQuestionType('');
            setAnswerType('');
            setQuestionModel('');
            setLabel('');
            setAnswer('');
            setOption1Text('');
            setOption1Image('');
            setOption1Audio('');
            setOption2Text('');
            setOption2Image('');
            setOption2Audio('');
            setOption3Text('');
            setOption3Image('');
            setOption3Audio('');
            setOption4Text('');
            setOption4Image('');
            setOption4Audio('');
            toast.success('Question created successfully!');
            setIsSubmitting(false);

        } catch (error) {
            console.error('Error creating question:', error);
            setIsSubmitting(false);
            toast.error('Error creating question:', error);

        }
    };
    return (
        <div className="main">
            <Sidebar isOpen={isSidebarOpen} />
            <div className="content">
                <Navbar toggleSidebar={toggleSidebar} />
                <div className="container-fluid px-4 py-3">
                    <div className="row d-flex justify-content-between">
                        <div className="col-auto">
                            <h1>Create Question</h1>
                        </div>
                        <div className="col-auto">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/" className="text-dark" style={{ textDecoration: 'none' }}>
                                    <FaHome />
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/questions" className="text-dark" style={{ textDecoration: 'none' }}>
                                    Questions
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Add Question</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row mt-3 px-3">
                        <div className="box px-3 py-3">
                        <form onSubmit={handleSubmit}>
                                <div className="row mb-3">
                                    <div className="col-4">
                                        <label htmlFor="selectFaculty" className='form-label mb-3'>Select Faculty</label>
                                        <select name="selectFaculty" id="selectFaculty" className='form-control' value={selectedFaculty} onChange={handleFacultyChange} required>
                                            <option value="">Select Faculty</option>
                                            {faculties.map(faculty => (
                                                <option key={faculty.id} value={faculty.id}>{capitalizeEachWord(faculty.faculty)}                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-4">
                                        <label htmlFor="selectCourse" className='form-label mb-3'>Select Course</label>
                                        <select name="selectCourse" id="selectCourse" className='form-control' value={selectedCourse} onChange={handleCourseChange} required>
                                            <option value="">Select Course</option>
                                            {courses && Array.isArray(courses) && courses.map(course => (
                                                <option key={course.id} value={course.id}>{course.title}</option>
                                            ))}

                                        </select>
                                    </div>
                                    <div className="col-4">
                                        <label htmlFor="selectSet" className='form-label mb-3'>Select Set</label>
                                        <select name="selectSet" id="selectSet" className='form-control' value={setValue} onChange={handleSelectSet} required>
                                            <option value="">Select Set</option>
                                            {sets && Array.isArray(sets) && sets.map(set => (
                                                <option key={set.id} value={set.id}>{set.setname.toUpperCase()}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-3">
                                        <label htmlFor="questionNo" className='form-label'>Question Number</label>
                                        <input type="number" className='form-control' name='question_number' value={questionNumber} onChange={handleQuestionNumberChange} />
                                        {isQuestionExists ? <p style={{color:'red'}}>Question exists for the selected set number.</p> : null}
                                        {!isQuestionExists && questionNumber ? <p style={{color:'green'}}>Question number available..</p> : null}
                                    </div>
                                    <div className="col-3">
                                        <label htmlFor="selectModel" className='form-label'>Question Modal</label>
                                        <select name="question_model" id="question_model" className='form-control' value={questionModal} onChange={handleQuestionModal} required>
                                            <option value="">Select Question Modal</option>
                                            <option value="listening">Listening</option>
                                            <option value="reading">Reading</option>
                                        </select>
                                    </div>
                                    <div className="col-3">
                                        <label htmlFor="question_type" className="form-label">Select Question Type</label>
                                        <select name="question_type" id="question_type" className="form-control" value={questionType} onChange={handleQuestionTypeChange} required>
                                        <option value="">Select Question Type</option>
                                        <option value="text">Text</option>
                                        <option value="audio">Audio</option>
                                        <option value="image">Image</option>
                                        </select>
                                    </div>
                                    <div className="col-3">
                                        <label htmlFor="answer_type" className='form-label'>Select Answer Type</label>
                                        <select name="answer_type" id="answer_type" className='form-control' value={answerType} onChange={handleAnswerTypeChange} required> 
                                            <option value="">Select Answer Type</option>
                                            <option value="text">Text</option>
                                            <option value="audio">Audio</option>
                                            <option value="image">Image</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label htmlFor="questionLabel" className='form-label mb-3'>Label Questions</label>
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            data={label}
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                setLabel(data); 
                                            } }
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        {renderQuestionInput()}
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        {renderAnswerInput()}
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-3">
                                        <label htmlFor="correctAnswer" className='form-label mb-3' style={{marginRight:'20px'}}>Correct Answer:</label>
                                        <select name="answer" id="answer" className='form-control' value={answer} onChange={handleAnswerChange} required> 
                                            <option value="">Select Answer</option>
                                            <option value="a">A</option>
                                            <option value="b">B</option>
                                            <option value="c">C</option>
                                            <option value="d">D</option>
                                        </select>

                                    </div>  
                                </div>
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-end">
                                        <button className='btn btn-primary' disabled={isAddQuestionDisabled}>
                                            {isSubmitting ? 'Submitting...' : 'Add Question'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <ToastContainer />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
function capitalizeEachWord(str) {
    return str.replace(/\b\w/g, c => c.toUpperCase());
  }
  
export default CreateQuestion

