import React from 'react';

const DashboardBox = ({ icon, label, value, color }) => {
  return (
    <div className="col-sm-6 col-md-3">
      <div className="dashboard-box py-3 px-3 d-flex justify-content-between" style={{ background: color, borderRadius:'10px' }}>
        <div className="col-3" style={{ fontSize: '3rem' }}>
          {icon}
        </div>
        <div className="col-9">
          <div className="row text-center" style={{ fontSize: '1.5rem', fontWeight: '600', color:'#3A3A3A'}}>
            <span>{label}</span>
          </div>
          <div className="row text-center" style={{ fontSize: '1.2rem', fontWeight: '500', color:'#3A3A3A' }}>
            <span>{value}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardBox;
