import React, { useState, useEffect } from 'react';
import apiClient from '../apiClient';
import { toast } from 'react-toastify';
const UpdateStudentUserProfile = ({ selectedUserId }) => {
  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    username: '',
    phone: '',
    guardian: '',
    address: ''
  });
  const [loading, setLoading] = useState(false);
  const [usernameReadOnly, setUsernameReadOnly] = useState(false);
  const [usernameValidationMessage, setUsernameValidationMessage] = useState('');
  const [phoneValidationMessage, setPhoneValidationMessage] = useState('');
  const [emailValidationMessage, setEmailValidationMessage] = useState('');

  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        setLoading(true);
        const response = await apiClient.get(`api/edit/${userId}/`);
        const userData = response.data;
        setFormData(prevState => ({
          ...prevState,
          full_name: userData.full_name || '',
          email: userData.email || '',
          username: userData.username || '',
          phone: userData.phone || '',
          guardian: userData.guardian || '',
          address: userData.address || ''
        }));

        setUsernameReadOnly(!!userData.username);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData(selectedUserId);
  }, [selectedUserId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (name === 'phone') {
      const phoneRegex = /^\d{10}$/;
      if (!phoneRegex.test(value)) {
        setPhoneValidationMessage('Phone number must be 10 digits');
      } else {
        setPhoneValidationMessage('');
      }
    }

    if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
      if (!emailRegex.test(value)) {
        setEmailValidationMessage('Invalid email format');
      } else {
        setEmailValidationMessage('');
      }
    }
  };

  const handleUsernameBlur = () => {
    if (formData.username) {
      apiClient.get(`api/check-username-exists/${formData.username}/`)
        .then(response => {
          if (response.data.exists) {
            setUsernameValidationMessage('Username already exists');
          } else {
            setUsernameValidationMessage('');
          }
        })
        .catch(error => {
          console.error('Error checking username:', error);
          setUsernameValidationMessage('');
        });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await apiClient.put(`api/edit/${selectedUserId}/`, formData);
      toast.success('User information updated successfully');
    } catch (error) {
      console.error('Error updating user profile:', error);
    } finally {
      setLoading(false);
    }
  };
  const capitalizeTitle = (title) => 
    title.replace(/\b\w/g, char => char.toUpperCase());
  const lowerCase = (title) => title.toLowerCase();

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row py-3">
          <div className="col-6">
            <div className="mb-3">
              <label className="form-label">Full Name:</label>
              <input type="text" name="full_name" value={capitalizeTitle(formData.full_name)} onChange={handleChange} className="form-control" />
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <label className="form-label">Username:</label>
              <input type="text" name="username" value={lowerCase(formData.username)} onChange={handleChange} onBlur={handleUsernameBlur} className="form-control" readOnly={usernameReadOnly} />
              {usernameValidationMessage && <span className="text-danger">{usernameValidationMessage}</span>}
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <label className="form-label">Email:</label>
              <input type="email" name="email" value={lowerCase(formData.email)} onChange={handleChange} className="form-control" />
              {emailValidationMessage && <span className="text-danger">{emailValidationMessage}</span>}
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <label className="form-label">Phone:</label>
              <input type="text" name="phone" value={formData.phone} onChange={handleChange} className="form-control" />
              {phoneValidationMessage && <span className="text-danger">{phoneValidationMessage}</span>}
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <label className="form-label">Guardian Name:</label>
              <input type="text" name="guardian" value={capitalizeTitle(formData.guardian)} onChange={handleChange} className="form-control" />
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <label className="form-label">Address:</label>
              <input type="text" name="address" value={capitalizeTitle(formData.address)} onChange={handleChange} className="form-control" />
            </div>
          </div>
          <div className="col-12">
            <p>{formData.remark}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-end">
            <button type="submit" className="btn btn-primary" disabled={loading}>
              {loading ? 'Updating...' : 'Update Profile'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateStudentUserProfile;
