import React, { useState, useEffect } from 'react';
import Navbar from '../Navbars/Navbar';
import Sidebar from '../Navbars/Sidebar';
import apiClient from '../apiClient';
import ReactPaginate from 'react-paginate';
import { TbArrowsDownUp } from "react-icons/tb";
import { FaFilter } from "react-icons/fa";
import { Modal, Table } from 'react-bootstrap';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import UserProfile from './UserProfile';
import UpdateStudentUserProfile from './../Users/UpdateStudentUserProfile';
import AddCourseToNewUser from './../Users/AddCourseToNewUser';
import { Tabs, Tab } from 'react-bootstrap';
import UserBilling from '../Billing/UserBilling';
import ActiveCourseInfo from './ActiveCourseInfo';

const Students = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [students, setStudents] = useState([]);
    const [courses, setCourses] = useState([]);
    const [shifts, setShifts] = useState([]);
    const [selectedShift, setSelectedShift] = useState('');
    const [selectedCourse, setSelectedCourse] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [studentsPerPage, setStudentsPerPage] = useState(10);
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setEditUserModal] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [showPaymentModal, setPaymentModal] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        fetchData();
        fetchShifts();
        fetchCourses();
    }, []);

    const fetchData = async () => {
        try {
            const response = await apiClient.get('api/active-users/');
            setStudents(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchCourses = async () => {
        try {
            const response = await apiClient.get('api/all-course/');
            setCourses(response.data);
        } catch (error) {
            console.error('Error fetching courses:', error);
        }
    };

    const fetchShifts = async () => {
        try {
            const response = await apiClient.get('api/get-shift-data/');
            setShifts(response.data);
        } catch (error) {
            console.error('Error fetching shifts:', error);
        }
    };

    const handleShiftChange = (event) => {
        setSelectedShift(event.target.value);
    };

    const handleCourseChange = (event) => {
        setSelectedCourse(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleShowChange = (event) => {
        setStudentsPerPage(parseInt(event.target.value));
    };

    const filterStudents = () => {
        let filteredStudents = students;
    
        if (selectedShift) {
            filteredStudents = filteredStudents.filter(student => student.shift.id === parseInt(selectedShift));
        }
    
        if (selectedCourse) {
            filteredStudents = filteredStudents.filter(student => student.course.id === parseInt(selectedCourse));
        }
    
        // Apply search filtering
        if (searchQuery) {
            const cleanedSearchQuery = searchQuery.replace(/\D/g, '');
    
            filteredStudents = filteredStudents.filter(student => {
                const cleanedPhoneNumber = student.user.phone.replace(/\D/g, '');
                return cleanedPhoneNumber.includes(cleanedSearchQuery);
            });
        }
    
        return filteredStudents;
    };
    

    const handleIconClick = () => {
        setSelectedShift('');
        setSelectedCourse('');
        setSearchQuery('');
    };
    
    
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    const offset = currentPage * studentsPerPage;
    const pageCount = Math.ceil(filterStudents().length / studentsPerPage);


    const handleShowUserInfo = (userId) => {
        setSelectedUserId(userId);
        setShowModal(true);
    };

    const handleEditAccountInfo = async (userId) => {
        setSelectedUserId(userId);
        setEditUserModal(true);
    };


    const handleDeactivateCourse = async (userId) => {
        const currentDate = new Date().toISOString();

        try {
            await apiClient.put(`api/deactivate/${userId}/`, { active_status: false, course_ended: currentDate });
            fetchData(); 
        } catch (error) {
            console.error('Error deactivating user:', error);
        }
    };

    const handleBillingPayment = async (userId) => {
        setSelectedUserId(userId)
        setPaymentModal(true);
    }
   
    const handleDeactivateAccount = async () => {
        try {
            const response = await apiClient.post(`api/deactivate-user/${selectedUserId}/`, { is_deactive: true, is_active: false });
            console.log('User deactivated:', response.data);
            setShowModal(false);
            fetchData();
            toast.success('User deactivated Successfully.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                });
        } catch (error) {
            console.error('Error deactivating user:', error);
        }
    };
    
    
    const capitalizeTitle = (title) => 
        title.replace(/\b\w/g, char => char.toUpperCase());
    return (
        <div className="main">
            <Sidebar isOpen={isSidebarOpen} />
            <div className="content">
                <Navbar toggleSidebar={toggleSidebar} />
                <div className="container-fluid px-4 py-3">
                <div className="row my-2 align-items-center d-block">
                    <div className="col-md-6">
                        <h1>Student Lists</h1>
                        
                    </div>
                    <div className="col-md-12 mb-3">
                        <div className="row d-flex">
                            <div className="col-md-3">
                                <form className="d-flex align-items-center justify-content-between">
                                    <div className='d-flex' style={{ width: '30%', textAlign: 'right', paddingRight: '10px' }}>
                                        <label className="mb-0" style={{ marginLeft: '10px' }}>Shift <FaFilter /> :</label>
                                    </div>
                                    <div style={{ width: '70%' }}>
                                        <select 
                                            className="form-control custom-select" 
                                            style={{ height: '38px', fontSize: '14px' }}
                                            value={selectedShift}
                                            onChange={handleShiftChange}>

                                            <option value="" disabled>Filter with Shift</option>
                                            {shifts.map(shift => (
                                                <option key={shift.id} value={shift.id}>{shift.shiftname}</option>
                                            ))}
                                        </select>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-3">
                                <form className="d-flex align-items-center justify-content-between">
                                    <div style={{ width: '35%', textAlign: 'right', paddingRight: '10px' }}>
                                        <label className="mb-0">Course <FaFilter /> :</label>
                                    </div>
                                    <div style={{ width: '65%' }}>
                                        <select 
                                            className="form-control custom-select" 
                                            style={{ height: '38px', fontSize: '14px' }}
                                            value={selectedCourse}
                                            onChange={handleCourseChange}
                                        >
                                            <option value="" disabled>Filter with Course</option>
                                            {courses.map(course => (
                                                <option key={course.id} value={course.id}>{course.title}</option>
                                            ))}
                                        </select>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-3">
                                <div className="row d-flex px-0 py-0 justify-content-between">
                                    <div className="col px-0 py-0">
                                        <form className="d-flex align-items-center mx-3">
                                            <label className="mr-2 mb-0">Show: </label>
                                            <select className="form-control custom-select mx-2" style={{ height: '38px', fontSize: '14px', width:'50px'}} onChange={handleShowChange} value={studentsPerPage}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                            <span className='mx-1 mb-0'>Items</span>
                                        </form>
                                    </div>
                                    <div className="col px-0 py-0">
                                        <div className="icon mx-3 mt-1">
                                            <span className="reset-filter mx-1 mb-0 px-2 py-1" style={{ cursor: 'pointer' }}onClick={handleIconClick}><TbArrowsDownUp/></span>
                                        </div>
                                    </div>
                                </div>   
                            </div>
                            <div className="col-md-3">
                                <form className="input-group ml-auto">
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Search by phone number" 
                                        style={{ height: '38px', fontSize: '14px' }} 
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" type="submit" style={{ height: '38px', fontSize: '14px' }}>Search</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                    <>
                        <div className="box">
                    
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Course</th>
                                    <th>Shift</th>
                                    <th>Phone</th>
                                    <th>Course Joined</th>
                                    <th className='text-center'>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filterStudents().slice(offset, offset + studentsPerPage).length > 0 ? (
                                    filterStudents().slice(offset, offset + studentsPerPage).map((student, index) => (
                                        <tr key={student.id}>
                                            <td>{offset + index + 1}</td>
                                            <td>{capitalizeTitle(student.user.full_name)}</td>
                                            <td>{capitalizeTitle(student.course.title)}</td>
                                            <td>{student.shift.shiftname}</td>
                                            <td>{student.user.phone}</td>
                                            <td>{student.date}</td>
                                            <td className='text-center'>
                                                <div className="tooltip-container">
                                                    <span className="tooltip-text">View Info</span>
                                                    <button className='btn btn-sm btn-success' onClick={() => handleShowUserInfo(student.user.id)}>
                                                    <i className="fa-solid fa-eye"></i>
                                                    </button>
                                                </div>
                                                <div className="tooltip-container">
                                                    <span className="tooltip-text">Edit User</span>
                                                    <button className='btn btn-sm btn-primary mx-2' onClick={() => handleEditAccountInfo(student.user.id)}>
                                                        <i className="fa-solid fa-pen-to-square"></i>
                                                    </button>
                                                </div>
                                                <div className="tooltip-container">
                                                    <span className="tooltip-text">Deactivate Course</span>
                                                    <button className='btn btn-sm btn-danger' onClick={() => handleDeactivateCourse(student.id)}>
                                                        <i className="fa-solid fa-delete-left"></i>                                       
                                                    </button>
                                                </div>
                                                <div className="tooltip-container">
                                                    <span className="tooltip-text">Make payment to user</span>
                                                    <button className='btn btn-sm btn-info mx-2' onClick={() => handleBillingPayment(student.user.id)}>Make Payment</button>
                                                </div>
                                                

                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="7" className="text-center">No records found</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>


                        </div>
                            <ReactPaginate
                                pageCount={pageCount}
                                onPageChange={handlePageChange}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                                previousLabel={currentPage === 0 ? null : 'Previous'}
                                nextLabel={currentPage === pageCount - 1 ? null : 'Next'}
                                previousClassName={currentPage === 0 ? 'pagination__link pagination__link--hidden' : 'pagination__link'}
                                nextClassName={currentPage === pageCount - 1 ? 'pagination__link pagination__link--hidden' : 'pagination__link'}
                                previousLinkClassName={'pagination__link'}
                                nextLinkClassName={'pagination__link'}
                            />
                        <ToastContainer />
                    </>
                    </div>
                </div>
                <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>User Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <UserProfile userId={selectedUserId} />
                        <ActiveCourseInfo userId={selectedUserId} />
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-danger btn-sm' onClick={handleDeactivateAccount}>Deactivate Account</button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showEditModal} onHide={() => setEditUserModal(false)} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Update Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Tabs defaultActiveKey="tab1">
                            <Tab eventKey="tab1" title="Personal Information">
                            <UpdateStudentUserProfile selectedUserId={selectedUserId}/>
                            </Tab>
                            <Tab eventKey="tab2" title="Course Information">
                            <AddCourseToNewUser selectedUserId={selectedUserId}/>
                            </Tab>
                        </Tabs>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>

                <Modal show={showPaymentModal} onHide={() => setPaymentModal(false)} size="xl" keyboard={false} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Billing</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <UserBilling userId={selectedUserId}></UserBilling>
                    </Modal.Body>
                </Modal>

                   
                    
            </div>
    );
}

export default Students;
