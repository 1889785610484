import React, { useState, useEffect } from 'react';
import Navbar from '../Navbars/Navbar';
import Sidebar from '../Navbars/Sidebar';
import { Link } from 'react-router-dom'; 
import { Modal, Table, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import Downloads from './Downloads';
import apiClient from '../apiClient';
import { FaHome } from "react-icons/fa";

const ManageDownloads = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [resources, setResources] = useState([]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleAddDownload = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    fetchResources();

  };

  const handleShowDeleteModal = (id) => {
    setDeleteId(id);
    setDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteId(null);
    setDeleteModal(false);
  };

  const handleDelete = async () => {
    try {
      await apiClient.delete(`/api/resources-delete/${deleteId}/`);
      fetchResources();
      handleCloseDeleteModal();
      toast.success('Resource deleted successfully');
    } catch (error) {
      console.error('Error deleting resource:', error);
      toast.error('Failed to delete resource');
    }
  };

  useEffect(() => {
    fetchResources();
  }, []);

  const fetchResources = async () => {
    try {
      const response = await apiClient.get('/api/resources-list/');
      setResources(response.data);
    } catch (error) {
      console.error('Error fetching resources:', error);
      toast.error('Failed to fetch resources');
    }
  };
  
  return (
    <div className="main">
      <Sidebar isOpen={isSidebarOpen} />
      <div className="content">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="container-fluid px-4 py-3">
          <div className="row d-flex justify-content-between">
            <div className="col-auto">
              <h1>Manage Downloads</h1>
            </div>
            <div className="col-auto d-flex">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/" className="text-dark" style={{ textDecoration: 'none' }}>
                      <FaHome />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/site" className="text-dark" style={{ textDecoration: 'none' }}>
                      Site
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Manage Ads</li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="row d-flex justify-content-end">
            <div className="col-auto">
              <button className='btn btn-sm' style={{background:'#138D75', color:'#fff'}} onClick={handleAddDownload}>Add Download Data</button>
            </div>
          </div>
          <div className="row mt-3 px-3">
            <div className="box">
                <Table>
                    <thead>
                        <tr>
                            <th>S.N.</th>
                            <th>Title</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {resources.length === 0 ? (
                            <tr>
                            <td colSpan="3">No data found</td>
                            </tr>
                        ) : (
                            resources.map((resource, index) => (
                            <tr key={resource.id}>
                                <td>{index + 1}</td>
                                <td>{resource.title}</td>
                                <td>
                                <Button variant="danger btn-sm" onClick={() => handleShowDeleteModal(resource.id)}>Delete</Button>
                                </td>
                            </tr>
                            ))
                        )}
                    </tbody>

                </Table>
              <Modal show={showModal} onHide={handleCloseModal} size='lg'>
                <Modal.Header closeButton>
                  <Modal.Title>Add Download Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Downloads handleCloseModal={handleCloseModal} />
                </Modal.Body>
              </Modal>
              <Modal show={deleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete this resource?
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseDeleteModal}>Cancel</Button>
                  <Button variant="danger" onClick={handleDelete}>Confirm</Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
}

export default ManageDownloads;
