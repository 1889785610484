import React, { useState, useEffect } from 'react';
import apiClient from '../apiClient';
import { toast } from 'react-toastify';

const SendMessage = ({ handleCloseModal }) => {
  const [faculties, setFaculties] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [courses, setCourses] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [selectedShift, setSelectedShift] = useState('');
  const [tableData, setTableData] = useState([]);
  const [message, setMessage] = useState('');
  const [selectedUsers, setSelectedUsers] = useState({});

  useEffect(() => {
    apiClient.get('api/faculties/')
      .then(response => {
        setFaculties(response.data);
      })
      .catch(error => {
        console.error('Error fetching faculties:', error);
      });
  }, []);

  useEffect(() => {
    if (selectedFaculty) {
      apiClient.get(`api/courses/${selectedFaculty}/filter/`)
        .then(response => {
          setCourses(response.data.courses);
        })
        .catch(error => {
          console.error('Error fetching courses:', error);
        });
    }
  }, [selectedFaculty]);

  useEffect(() => {
    if (selectedCourse) {
      apiClient.get(`api/shift/${selectedCourse}/filter/`)
        .then(response => {
          setShifts(response.data.shift);
        })
        .catch(error => {
          console.error('error fetching shifts:', error);
        })
    }
  }, [selectedCourse]);

  useEffect(() => {
    if (selectedCourse && selectedShift) {
      apiClient.get(`api/courseselected/${selectedCourse}/${selectedShift}/`)
        .then(response => {
          setTableData(response.data);
          const initialSelectedUsers = {};
          response.data.forEach(data => {
            initialSelectedUsers[data.user.id] = true;
          });
          setSelectedUsers(initialSelectedUsers);
        })
        .catch(error => {
          console.error('Error fetching table data:', error);
        });
    }
  }, [selectedCourse, selectedShift]);

  const handleCheckboxChange = (userId) => {
    setSelectedUsers(prevState => ({
      ...prevState,
      [userId]: !prevState[userId]
    }));
  }

  const handleAddCourseInfo = (event) => {
    event.preventDefault();

    const formData = tableData
      .filter(data => selectedUsers[data.user.id])
      .map(data => ({
        user: data.user.id.toString(),
        phone: data.user.phone,
        message: message
      }));

    apiClient.post('api/send-messages/', formData)
      .then(response => {
        console.log('API response:', response.data);
        response.data.forEach(res => {
          if (res.message === 'Message created and sent successfully') {
            toast.success(res.message);
          } else {
            toast.error(res.sms_error);
          }
        });
        setMessage('');
        handleCloseModal();
      })
      .catch(error => {
        console.error('Error sending messages:', error);
        toast.error('Failed to send messages');
      });
  }

  return (
    <div className="send-message-container">
      <form onSubmit={handleAddCourseInfo}>
        <div className="row d-flex my-3 justify-content-between">
          <div className="col">
            <label htmlFor="faculty" className='form-label'>Faculty</label>
            <select name="faculty" id="faculty" className='form-control' onChange={(e) => setSelectedFaculty(e.target.value)}>
              <option value="">Select Faculty</option>
              {faculties.map(faculty => (
                <option key={faculty.id} value={faculty.id}>{faculty.faculty}</option>
              ))}
            </select>
          </div>
          <div className="col">
            <label htmlFor="course" className='form-label'>Course</label>
            <select id="course" className='form-control' onChange={(e) => setSelectedCourse(e.target.value)}>
              <option value="">Select Course</option>
              {Array.isArray(courses) && courses.map(course => (
                <option key={course.id} value={course.id}>{course.title}</option>
              ))}
            </select>
          </div>
          <div className="col">
            <label htmlFor="shift" className='form-label'>Shift</label>
            <select name="shift" id="shift" className='form-control' onChange={(e) => setSelectedShift(e.target.value)}>
              <option value="">Select Shift</option>
              {Array.isArray(shifts) && shifts.map(shift => (
                <option key={shift.id} value={shift.id}>{shift.shiftname}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label htmlFor="addMessage">Message</label>
            <textarea
              name="message"
              id="message"
              className='form-control'
              cols="30"
              rows="2"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className="row d-flex justify-content-end mt-3">
            <div className="col-auto">
            <button type="submit" className="btn btn-primary">Send Message</button>
            </div>
        </div>
      </form>

      <div className="row">
        <div className="col">
          <table className="table">
            <thead>
              <tr>
                <th>S.N.</th>
                <th>Name</th>
                <th>Course</th>
                <th>Shift</th>
                <th>Phone</th>
                <th>Select</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((data, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{data.user.full_name}</td>
                  <td>{data.course.title}</td>
                  <td>{data.shift.shiftname}</td>
                  <td>{data.user.phone}</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedUsers[data.user.id]}
                      onChange={() => handleCheckboxChange(data.user.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SendMessage;
