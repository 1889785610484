import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import apiClient from '../apiClient';
import { toast } from 'react-toastify';
const AddBanner = ({ onBannerUploadSuccess  }) => {
    const [formData, setFormData] = useState({
        title: '',
        image: null,
        caption: ''
    });

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({ ...formData, [name]: files ? files[0] : value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('title', formData.title);
        formDataToSend.append('image', formData.image);
        formDataToSend.append('caption', formData.caption);

        try {
            const response = await apiClient.post('/api/create-banner/', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Banner created:', response.data);
            toast.success('Image Uploaded Successfully!');
            onBannerUploadSuccess(); 
            setFormData({ title: '', image: null, caption: '' });
        } catch (error) {
            console.error('Error creating banner:', error);
        }
    };

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="title">
                    <Form.Label>Title</Form.Label>
                    <Form.Control type="text" name="title" value={formData.title} onChange={handleInputChange} />
                </Form.Group>
                <Form.Group controlId="caption">
                    <Form.Label>Caption</Form.Label>
                    <Form.Control as="textarea" rows={3} name="caption" value={formData.caption} onChange={handleInputChange} />
                </Form.Group>
                <Form.Group controlId="image">
                    <Form.Label>Image</Form.Label>
                    <Form.Control type="file" name="image" onChange={handleInputChange} />
                </Form.Group>
                
                <Button variant="primary" className='mt-3' type="submit" style={{width:'100%'}}>Submit</Button>
            </Form>
        </div>
    );
}

export default AddBanner;
