import React, { useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import apiClient from '../apiClient';
import { toast } from 'react-toastify';

const EditPost = ({ postId }) => {
  const [eventData, setEventData] = useState({
    description: '',
    name: '', 
  });

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await apiClient.get(`/api/events/${postId}/`);
        const { description, name } = response.data;
        setEventData({
          description: description,
          name: name, 
        });
      } catch (error) {
        console.error('Error fetching event data:', error);
      }
    };

    if (postId) {
      fetchEventData();
    }
  }, [postId]);


  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEventData({
      ...eventData,
      description: data,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await apiClient.put(`/api/events-description/${postId}/`, eventData);
      toast.success('Event Updated Successfully', eventData);
    } catch (error) {
      console.error('Error updating event data:', error);
    }
  };

  return (
    <div className="container mt-4">
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Description:</label>
          <CKEditor
            editor={ClassicEditor}
            data={eventData.description}
            onChange={handleEditorChange}
          />
        </div>
        <div className="col-12 d-flex justify-content-end">
          <button type="submit" className="btn btn-primary">Save Changes</button>
        </div>
      </form>
    </div>
  );
}

export default EditPost;
