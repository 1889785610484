import React, { useState, useEffect } from 'react';
import apiClient from '../apiClient';

const ExamHistory = () => {
  const [completedExams, setCompletedExams] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  useEffect(() => {
    const fetchCompletedExams = async () => {
      try {
        const response = await apiClient.get(`/api/exam-schedule-by-date/?start_date=${fromDate}&end_date=${toDate}`);
        const sortedExams = response.data.sort((a, b) => new Date(b.schedule_date) - new Date(a.schedule_date));
        setCompletedExams(sortedExams);
      } catch (error) {
        console.error('Error fetching completed exams:', error);
      }
    };

    fetchCompletedExams();
  }, [fromDate, toDate]);

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const getDate15DaysAgo = () => {
    const today = new Date();
    const fifteenDaysAgo = new Date(today);
    fifteenDaysAgo.setDate(today.getDate() - 15);
    return fifteenDaysAgo.toISOString().split('T')[0];
  };

  useEffect(() => {
    setFromDate(getDate15DaysAgo());
    setToDate(new Date().toISOString().split('T')[0]);
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-auto">
          <label htmlFor="from" className='form-label'>From</label>
          <input type="date" name='date' className='form-control' value={fromDate} onChange={handleFromDateChange} />
        </div>
        <div className="col-auto">
          <label htmlFor="to" className='form-label'>To</label>
          <input type="date" className='form-control' value={toDate} onChange={handleToDateChange} />
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Set</th>
            <th>Schedule Date</th>
            <th>Exam Code</th>
            <th>Scheduled By</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {completedExams.map((exam, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{exam.set.setname}</td>
              <td>{formatDate(exam.schedule_date)}</td>
              <td>{exam.slug}</td>
              <td>{exam.scheduled_by}</td>
              <td>{exam.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default ExamHistory;
