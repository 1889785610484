import React, { useState, useEffect } from 'react';
import Navbar from '../Navbars/Navbar';
import Sidebar from '../Navbars/Sidebar';
import { BsCalendar } from 'react-icons/bs'; 
import apiClient from '../apiClient';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import ExamHistory from './ExamHistory';

const Exams = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [sets, setSets] = useState([]);
  const [selectedSet, setSelectedSet] = useState('');
  const [exams, setExams] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState('');
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [faculties, setFaculties] = useState([]);
  const [showExamHistoryModal, setShowExamHistoryModal] = useState(false); 

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const fetchFaculties = async () => {
      try {
        const response = await apiClient.get('api/faculties/');
        setFaculties(response.data);
      } catch (error) {
        console.error('Error fetching faculties:', error);
      }
    };

    fetchFaculties(); 
  }, []); 

  useEffect(() => {
    const fetchOngoingExams = async () => {
      try {
        const response = await apiClient.get('api/ongoing-exam-schedules/');
        setExams(response.data);
      } catch (error) {
        console.error('Error fetching ongoing exams:', error);
      }
    };

    fetchOngoingExams(); 
  }, []); 

  const handleFacultyChange = async (e) => {
    const facultyId = e.target.value;
    setSelectedFaculty(facultyId);
    try {
      const response = await apiClient.get(`api/courses/${facultyId}/filter/`);
      setCourses(response.data.courses);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const handleCourseChange = async (e) => {
    const courseId = e.target.value;
    setSelectedCourse(courseId);
    try {
      const response = await apiClient.get(`api/courses/${courseId}/sets/`);
      setSets(response.data);
    } catch (error) {
      console.error('Error fetching sets:', error);
    }
  };
  
  const handleSelectSet = (e) => {
    setSelectedSet(e.target.value);
  }

  const handleScheduleNow = () => {
    if (selectedSet) {
      const authToken = localStorage.getItem('authToken');
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      };
  
      apiClient.get('auth/api/current-user/', { headers })
        .then(response => {
          const username = response.data.username;
  
          apiClient.post('api/exam-schedule/', { set: selectedSet, scheduled_by: username }, { headers })
            .then(response => {
              console.log('Exam scheduled successfully:', response.data);
              toast.success('Exam scheduled successfully!');
              apiClient.get('api/ongoing-exam-schedules/')
                .then(response => {
                  console.log('Ongoing exams fetched successfully:', response.data);
                  setExams(response.data);
                  setSelectedSet('');
                  setSelectedFaculty('');
                  setCourses([]);
                  setSets([]);
                  
                })
                .catch(error => {
                  console.error('Error fetching ongoing exams:', error);
                });
            })
            .catch(error => {
              console.error('Error scheduling exam:', error);
            });
        })
        .catch(error => {
          console.error('Error fetching current user:', error);
        });
    } else {
      console.error('Please select a set before scheduling.');
    }
  };
  

  const formatDate = (dateString) => {
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatTime = (timeString) => {
    return new Date(timeString).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };


  const handleEndExam = async (scheduleId) => {
    if (!scheduleId) {
      console.error('Invalid schedule ID:', scheduleId);
      alert(`No Schedule ID:${scheduleId} `);
      return;
    }
    
    try {
      const authToken = localStorage.getItem('authToken');
      const headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${authToken}`,
      };
      const response = await apiClient.put(`api/exam-schedule/${scheduleId}/update-status/`, null, { headers });
      console.log('Status updated successfully:', response.data);
      toast.success('Exam ended successfully!');
      
      const ongoingExamsResponse = await apiClient.get('api/ongoing-exam-schedules/');
      setExams(ongoingExamsResponse.data);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };
  
  
  

  return (
    <div className="main">
      <Sidebar isOpen={isSidebarOpen} />
      <div className="content">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="container-fluid px-4 py-3">
          <div className="row align-items-center justify-content-between">
            <div className="col-auto">
              <h1>Schedule Exam</h1>
            </div>
            <div className="col-auto">
              <button className='btn btn-success'  onClick={() => setShowExamHistoryModal(true)}>Exam Histroy</button>
            </div>
          </div>
          <div className="box mt-3">
            <div className="row mb-3">
              <div className="col-4">
                <label htmlFor="selectFaculty" className="form-label">Select Faculty</label>
                <select name="selectFaculty" id="selectFaculty" className='form-control' value={selectedFaculty} onChange={handleFacultyChange} required>
                  <option value="">Select Faculty</option>
                  {faculties.map(faculty => (
                    <option key={faculty.id} value={faculty.id}>{capitalizeEachWord(faculty.faculty)}</option>
                  ))}
                </select>
              </div>
              <div className="col-4">
                <label htmlFor="selectCourse" className='form-label mb-3'>Select Course</label>
                <select name="selectCourse" id="selectCourse" className='form-control' value={selectedCourse} onChange={handleCourseChange} required>
                  <option value="">Select Course</option>
                  {courses && Array.isArray(courses) && courses.map(course => (
                    <option key={course.id} value={course.id}>{course.title}</option>
                  ))}
                </select>
              </div>
              <div className="col-4">
                <label htmlFor="selectSet" className='form-label mb-3'>Select Set</label>
                <select name="selectSet" id="selectSet" className='form-control' value={selectedSet} onChange={handleSelectSet} required>
                  <option value="">Select Set</option>
                  {sets && Array.isArray(sets) && sets.map(set => (
                    <option key={set.id} value={set.id}>{set.setname.toUpperCase()}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row d-flex justify-content-end mt-3">
              <div className="col-auto">
                <button className='btn btn-primary' onClick={handleScheduleNow}><BsCalendar className="me-1" />Schedule Now</button>
              </div>
            </div>
          </div>
          <hr />
          <table className="table table-striped mt-4">
            <thead>
              <tr>
                <th>#</th>
                <th>Set</th>
                <th>Scheduled Time</th>
                <th>Status</th>
                <th>Scheduled By</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {exams.map((exam, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{exam.set.setname.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</td>
                  <td>{formatDate(exam.schedule_date)} {formatTime(exam.schedule_date)}</td>
                  <td>{exam.status}</td>
                  <td>{exam.scheduled_by}</td>
                  <td><button className='btn-sm btn-danger btn' onClick={() => handleEndExam(exam.id)}>End</button></td>
                </tr>
              ))}
            </tbody>
          </table>
          <Modal show={showExamHistoryModal} onHide={() => setShowExamHistoryModal(false)} size='lg'>
            <Modal.Header closeButton>
              <Modal.Title>Exam History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ExamHistory/>
            </Modal.Body>
          </Modal>
          <ToastContainer/>
        </div>
      </div>
    </div>
);
}
function capitalizeEachWord(str) {
  return str.replace(/\b\w/g, c => c.toUpperCase());
}

export default Exams;
