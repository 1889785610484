import React, { useState, useEffect } from 'react';
import Navbar from '../Navbars/Navbar';
import Sidebar from '../Navbars/Sidebar';
import apiClient from '../apiClient';
import DashboardBox from './DashboardBox'; 
import { FaUser, FaChalkboardTeacher, FaUserGraduate, FaUserPlus } from "react-icons/fa";
import LastRequests from './LastRequests';
import MoneyStatics from './MoneyStatics';
import { Modal } from 'react-bootstrap';
import LoginFirst from './LoginFirst';

const Home = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [userData, setUserData] = useState(null);
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await apiClient.get('/api/user-count/');
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('showPasswordModal') === 'true') {
      setShowPasswordModal(true);
      localStorage.removeItem('showPasswordModal');
    }
  }, []);


  return (
    <div className="main">
      <Sidebar isOpen={isSidebarOpen} />
      <div className="content">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="container-fluid px-4 py-3">
          <h1>Dashboard</h1>
          <div className="row mt-3">
            {userData && (
              <>
                <DashboardBox
                  icon={<FaUser className="mr-2" color="#A31AA7"/>}
                  label="Active Users"
                  value={userData.active_users}
                  color="#F4D1F5"
                />
                <DashboardBox
                  icon={<FaChalkboardTeacher className="mr-2" color="#99990D"/>}
                  label="Active Teachers"
                  value={userData.active_teachers}
                  color="#F5F5D1"
                />
                <DashboardBox
                  icon={<FaUserGraduate className="mr-2" color="#0E8F5D"/>}
                  label="Ex Students"
                  value={userData.deactive_users}
                  color="#D1F5E7"
                />
                <DashboardBox
                  icon={<FaUserPlus className="mr-2" color="#2844E1"/>}
                  label="Requests"
                  value={userData.request}
                  color="#D8D1F5"
                />
              </>
            )}
          </div>
          <div className="row my-3">
            <div className="col-md-8">
                <LastRequests/>
            </div>
            <div className="col-md-4">
                <MoneyStatics/>
            </div>
            </div>
        </div>
      </div>

      <Modal show={showPasswordModal}>
        <Modal.Body>
          <p style={{color:'red', fontSize:'1.2rem'}}>* Please change your default password.</p>
          <LoginFirst/>
        </Modal.Body>

      </Modal>
    </div>
  );
}

export default Home;
