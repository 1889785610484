import React, { useState, useEffect } from 'react';
import Navbar from '../Navbars/Navbar';
import Sidebar from '../Navbars/Sidebar';
import { Table, Modal, Button } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import apiClient from '../apiClient';
import { ToastContainer, toast } from 'react-toastify';
import { FaHome } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Syllabus = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    coursetitle: '',
    description: ''
  });
  const [deletingSyllabusId, setDeletingSyllabusId] = useState(null); 
  const [editingSyllabusId, setEditingSyllabusId] = useState(null); 
  const [syllabusData, setSyllabusData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleAddBtnClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingSyllabusId(null);
    setFormData({ coursetitle: '', description: '' }); 
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    
    try {
        let response;
        if (editingSyllabusId) {
          response = await apiClient.put(`/api/update-syllabus/${editingSyllabusId}/`, formData);
        } else {
          response = await apiClient.post('/api/create-syllabus/', formData);
        }
        
        console.log(response.data.message);
        
        toast.success(response.data.message);
        setShowModal(false);
        setFormData({ coursetitle: '', description: '' }); 
        fetchData(); 
      } catch (error) {
        console.error('Error submitting form:', error);
        toast.error('Data Insertion failed');
      }
      
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const fetchData = async () => {
    try {
      const response = await apiClient.get('/api/get-all-syllabus/');
      setSyllabusData(response.data);
    } catch (error) {
      console.error('Error fetching data', error);
      toast.error('Failed to fetch syllabus data');
    }
  };

  const handleDeleteConfirmation = async () => {
    try {
      await apiClient.delete(`/api/delete-syllabus/${deletingSyllabusId}/`);
      toast.success('Syllabus deleted successfully');
      setDeletingSyllabusId(null);
      fetchData(); 
    } catch (error) {
      console.error('Error deleting syllabus:', error);
      toast.error('Failed to delete syllabus');
    }
  };

  const handleEditClick = (syllabusId) => {
    setEditingSyllabusId(syllabusId);
    setShowModal(true);
    const syllabus = syllabusData.find((item) => item.id === syllabusId);
    if (syllabus) {
      setFormData({
        coursetitle: syllabus.coursetitle,
        description: syllabus.description
      });
    }
  };

  return (
    <div className="main">
      <Sidebar isOpen={isSidebarOpen} />
      <div className="content">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="container-fluid px-4 py-3">
          <div className="row d-flex justify-content-between">
            <div className="col-auto">
              <h1>Syllabus</h1>
            </div>
            <div className="col-auto">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/" className="text-dark" style={{ textDecoration: 'none' }}>
                      <FaHome />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/site" className="text-dark" style={{ textDecoration: 'none' }}>
                      Site
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Events</li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="row d-flex justify-content-end">
          <div className="col-auto">
              <button className='btn btn-success btn-sm' onClick={handleAddBtnClick}>Add Syllabus</button>
            </div>
          </div>
          <div className="row mt-3 px-3">
            <div className="box">
              <Table>
                <thead>
                  <tr>
                    <th>S.N.</th>
                    <th>Title</th>
                    <th>Slug</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {syllabusData.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.coursetitle}</td>
                      <td>{item.slug}</td>
                      <td>
                        <button className='btn btn-sm btn-success mx-2' onClick={() => handleEditClick(item.id)}>Edit</button>
                        <button className='btn btn-sm btn-danger mx-2' onClick={() => setDeletingSyllabusId(item.id)}>Delete</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <Modal show={deletingSyllabusId !== null} onHide={() => setDeletingSyllabusId(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this syllabus?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeletingSyllabusId(null)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirmation}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal} onHide={handleCloseModal} size='lg' backdrop='static'>
        <Modal.Header closeButton>
          <Modal.Title>{editingSyllabusId ? 'Edit Syllabus' : 'Add Syllabus'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormSubmit}>
            <div className="mb-3">
              <label htmlFor="syllabus" className='form-label'>Syllabus Title</label>
              <input type="text" className='form-control' id="syllabus" name="coursetitle" value={formData.coursetitle} onChange={handleInputChange} />
            </div>
            <div className="mb-3">
              <label htmlFor="description" className='form-label'>Description</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setFormData({ ...formData, description: data });
                }}
              />
            </div>
            <Button variant="primary" type="submit">
              {editingSyllabusId ? 'Update' : 'Submit'}
            </Button>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer/>
    </div>
  );
};

export default Syllabus;
