import React, { useState, useEffect } from 'react';
import apiClient from '../apiClient';
import { Table, Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

const BannerData = () => {
    const [banners, setBanners] = useState([]);
    const [selectedBanner, setSelectedBanner] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiClient.get('/api/all-banners/');
                if (response.status >= 200 && response.status < 300) {
                    setBanners(response.data);
                } else {
                    throw new Error('Failed to fetch banner data');
                }
            } catch (error) {
                console.error('Error fetching banner data:', error);
            }
        };
        fetchData();
    }, []);

    const handleViewBanner = (banner) => {
        setSelectedBanner(banner);
        setShowModal(true);
    };

    const handleDeleteBanner = async () => {
        try {
            const response = await apiClient.delete(`/api/delete-banners/${selectedBanner.id}/`);
            if (response.status >= 200 && response.status < 300) {
                setBanners(banners.filter(banner => banner.id !== selectedBanner.id));
                toast.success('Image deleted successfully.')
                setShowModal(false); 
            } else {
                throw new Error('Failed to delete banner');
            }
        } catch (error) {
            console.error('Error deleting banner:', error);
            toast.error('Error deleting an image', error)
        }
    };

    return (
        <div>
            <Table responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Image</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {banners.map((banner, index) => (
                        <tr key={banner.id}>
                            <td>{index + 1}</td>
                            <td>{banner.title ? banner.title : 'No Title'}</td>
                            <td>
                                <img src={banner.image} alt={`Banner ${index}`} style={{ width: '100px', height: '100px' }} />
                            </td>
                            <td><button className='btn btn-success btn-sm' onClick={() => handleViewBanner(banner)}>View</button></td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Banner Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Title: {selectedBanner && selectedBanner.title ? selectedBanner.title : 'No Title'}</h5>
                    <p>Caption: {selectedBanner && selectedBanner.caption ? selectedBanner.caption : 'No Caption'}</p>
                    <img src={selectedBanner && selectedBanner.image} alt="Banner" style={{ width: '100%', height: 'auto' }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDeleteBanner}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default BannerData;
