import React, { useState } from 'react';
import Navbar from '../Navbars/Navbar';
import Sidebar from '../Navbars/Sidebar';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Description from './Description';

const AboutInstitute = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="main">
      <Sidebar isOpen={isSidebarOpen} />
      <div className="content">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="container-fluid px-4 py-3">
          <div className="row">
            <div className="col">
              <h1>About Institute</h1>
            </div>
            <div className="col d-flex justify-content-end">
              <button className='btn btn-sm btn-success' onClick={openModal}>Add Content</button>
            </div>
          </div>
          <div className="row mt-3 px-3">
            <div className="box">

            </div>
          </div>
          {/* React Bootstrap Modal */}
          <Modal show={showModal} onHide={closeModal} size='lg'>
            <Modal.Header closeButton>
              <Modal.Title>About Golden Future Institute</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Description/>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeModal}>
                Close
              </Button>
              <Button variant="primary">
                Save changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default AboutInstitute;
