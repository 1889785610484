import React from 'react';
import Logout from './Logout';
import './Navbar.css';

const Navbar = ({ toggleSidebar }) => {
    const username = localStorage.getItem('username'); 

    const handleLogout = () => {
        localStorage.removeItem('user_id');
    };

    return (
        <nav className="navbar navbar-fixed-top d-flex justify-content-end align-items-center px-3">
            {/* <div className='d-none'>
                <button onClick={toggleSidebar}><i className="fa-solid fa-bars"></i></button>
            </div> */}
            <div className='text-right'>
                <span>Welcome, {username}</span>
                <Logout setLoggedIn={handleLogout} />
            </div>
        </nav>
    );
};

export default Navbar;
