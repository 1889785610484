import React, { useState } from 'react';
import { Link } from 'react-router-dom'; 
import Navbar from '../Navbars/Navbar';
import Sidebar from '../Navbars/Sidebar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AccountWithDeactiveCourse from './AccountWithDeactiveCourse';
import AllUserData from './AllUserData';
import Teachers from './Teachers';
import { ToastContainer } from 'react-toastify';

const Users = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const fetchData = () => {
    // Define your fetchData logic here
  };

  return (
    <div className="main">
      <Sidebar isOpen={isSidebarOpen} />
      <div className="content">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="container-fluid px-4 py-3">
        <div className="row my-2 align-items-center">
          <div className="col-md-6">
            <h1>Users</h1>  
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <Link className='btn btn-success btn-sm' to="/users/create-user">Create User</Link>
          </div>
        </div>
          <Tabs defaultActiveKey="tab1" id="users-tabs">
            <Tab eventKey="tab1" title="All Users">
              <AllUserData onDataUpdated={fetchData} />
            </Tab>
            <Tab eventKey="tab2" title="All Teachers">
              <Teachers/>
            </Tab>
            <Tab eventKey="tab3" title="Account with Deactive Course">
              <AccountWithDeactiveCourse onDataUpdated={fetchData} />
            </Tab>
          </Tabs>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}

export default Users;
