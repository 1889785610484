import React, { useState, useEffect } from 'react';
import apiClient from '../apiClient';
import defaultProfile from './../assets/default-profile-pic.jpg'; 

const UserProfile = ({ userId }) => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await apiClient.get(`api/user/${userId}/`);
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  
    if (userId) {
      fetchUserData();
    }
  }, [userId]);

  if (!userData) {
    return <div>Loading...</div>;
  }

  const capitalizeTitle = (title) => 
    title.replace(/\b\w/g, char => char.toUpperCase());

  const lowerCase = (title) => title.toLowerCase();

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 mb-3" style={{ background: '#27AE60' }}>
          <p style={{ padding: '5px 10px', margin: '0', color: 'white' }}>
            <strong>Personal Information</strong>
          </p>
        </div>
        <div className="col-9">
          <h5>{userData.full_name}</h5>
          <p className='mb-0'><strong>Username: </strong>{lowerCase(userData.username || 'N/A')}</p>
          <p className='mb-0'><strong>Email: </strong>{lowerCase(userData.email || 'N/A')}</p>
          <p className='mb-0'><strong>Date of Join: </strong>{formatDate(userData.created_at) || 'N/A'}</p>
          <p className='mb-0'><strong>Address: </strong>{capitalizeTitle(userData.address || 'N/A')}</p>
          <p className='mb-0'><strong>Guardian: </strong>{capitalizeTitle(userData.guardian || 'N/A')}</p>
          <p className='mb-0'><strong>Phone: </strong>{userData.phone || 'N/A'}</p>
          <p className='mb-0'>
            <strong>Account Status:</strong>
            <span style={{
              backgroundColor: userData.is_deactive ? 'red' : 'green',
              color: '#fff',
              padding: '0px 10px',
              borderRadius: '10px'
            }}>
              {userData.is_deactive ? 'Inactive' : 'Active'}
            </span>
          </p>
        </div>
        <div className="col-3 d-flex align-items-center justify-content-center">
          <img
            src={userData && userData.image ? `${userData.image}` : defaultProfile}
            alt="profile"
            style={{
              width: '150px',
              height: '175px',
              objectFit: 'cover',
              border: '#ddd 2px solid',
              boxShadow: '0 3px 4px rgba(0, 0, 0, 0.3)'
            }}
            onContextMenu={handleContextMenu}
          />
        </div>
      </div>
    </div>
  );
}

const formatDate = (dateTimeString) => {
  if (!dateTimeString) return ''; 
  
  const date = new Date(dateTimeString); 
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
  
  return date.toLocaleString('en-US', options); 
};

export default UserProfile;
