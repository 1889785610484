import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import apiClient from '../apiClient';
import '../Settings/Settings.css';

const LoginFirst = ({ setLoggedIn }) => {
  const navigate = useNavigate(); 
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [userId, setUserId] = useState(null);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const response = await apiClient.get('auth/api/current-user/');
        setUserId(response.data.userId);
      } catch (error) {
        console.error('Error fetching user ID:', error);
      }
    };

    fetchUserId();
  }, []);

  const logout = async () => {
    try {
      await apiClient.post('auth/api/logout/');
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('username');
      localStorage.removeItem('userId');
      navigate('/login'); 
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'currentPassword') setCurrentPassword(value);
    else if (name === 'newPassword') setNewPassword(value);
    else if (name === 'confirmPassword') setConfirmPassword(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userId === null) {
      console.error('Unable to retrieve user ID.');
      return;
    }
    try {
      setIsChangingPassword(true);
      await apiClient.put(`/api/update-password/${userId}/`, {
        current_password: currentPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      });
      setMessage('Password updated successfully. Now login with your new password.');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      
      setTimeout(() => {
        logout();
      }, 4000); 

    } catch (error) {
      console.error(error.response?.data?.message || 'An error occurred while updating the password.');
    } finally {
      setIsChangingPassword(false);
    }
  };

  const toggleShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div>
      <div className="container">
        {message && <div className="alert alert-success">{message}</div>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="currentPassword" className='form-label'>Current Password:</label>
            <div className="input-group">
              <input 
                type={showCurrentPassword ? 'text' : 'password'} 
                className='form-control' 
                name="currentPassword" 
                value={currentPassword} 
                onChange={handleChange} 
                required 
              />
              <button 
                className="btn btn-outline-secondary toggle-button" 
                type="button" 
                onClick={toggleShowCurrentPassword}
              >
                {showCurrentPassword ? 'Hide' : 'Show'}
              </button>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="newPassword" className='form-label'>New Password:</label>
            <div className="input-group">
              <input 
                type={showNewPassword ? 'text' : 'password'} 
                className='form-control' 
                name="newPassword" 
                value={newPassword} 
                onChange={handleChange} 
                required 
              />
              <button 
                className="btn btn-outline-secondary toggle-button" 
                type="button" 
                onClick={toggleShowNewPassword}
              >
                {showNewPassword ? 'Hide' : 'Show'}
              </button>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword" className='form-label'>Confirm Password</label>
            <div className="input-group">
              <input 
                type={showConfirmPassword ? 'text' : 'password'} 
                className='form-control' 
                name="confirmPassword" 
                value={confirmPassword} 
                onChange={handleChange} 
                required 
              />
              <button 
                className="btn btn-outline-secondary toggle-button" 
                type="button" 
                onClick={toggleShowConfirmPassword}
              >
                {showConfirmPassword ? 'Hide' : 'Show'}
              </button>
            </div>
          </div>
          <div className="row d-flex justify-content-end">
            <div className="col-auto">
              <button type="submit" className={`btn btn-primary ${isChangingPassword ? 'disabled' : ''}`}>
                {isChangingPassword ? 'Changing Password...' : 'Change Password'}
              </button>            
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginFirst;
