import React, { useState, useEffect } from 'react';
import apiClient from '../apiClient';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import UserProfile from './UserProfile';
import AllSelectedCourses from './AllSelectedCourses';
import { toast, Bounce } from 'react-toastify';

const AllUserData = ({ onDataUpdated }) => { 
  const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchData = async () => {
    try {
      const response = await apiClient.get('api/deactivated-users/');
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleViewClick = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const handleReactivateAccount = async () => {
    try {
        const response = await apiClient.post(`api/reactivate-user/${selectedUser.id}/`, { is_deactive: false, is_active: true});
        console.log('User reactivated:', response.data);
        setShowModal(false);
        fetchData();
        onDataUpdated(); 
        toast.success('User reactivated Successfully.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });
    } catch (error) {
        console.error('Error reactivating user:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredUsers = userData.filter(user => {
    const fullName = user.full_name.toLowerCase();
    const email = user.email.toLowerCase();
    const phone = user.phone.toLowerCase();
    const query = searchQuery.toLowerCase();
    return fullName.includes(query) || email.includes(query) || phone.includes(query);
  });
  
  return (
    <div>
      <div className="box">
        <div className="d-flex justify-content-end mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search user by name or email or phone.."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Temporary Address</th>
              <th className='text-center'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user, index) => (
              <tr key={user.id}>
                <td>{index + 1}</td>
                <td>{user.full_name}</td>
                <td>{user.email}</td>
                <td>{user.phone}</td>
                <td>{user.address}</td>
                <td className='text-center'>
                  <button className='btn btn-success btn-sm mx-1' onClick={() => handleViewClick(user)}>View</button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>User Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserProfile userId={selectedUser && selectedUser.id} /> 
          <AllSelectedCourses userId={selectedUser && selectedUser.id} />
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-primary btn-sm' onClick={handleReactivateAccount}>Reactivate Account</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AllUserData;
