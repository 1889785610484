import React, { useState } from 'react';
import Navbar from '../Navbars/Navbar';
import Sidebar from '../Navbars/Sidebar';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import apiClient from '../apiClient';
import { Link } from 'react-router-dom';

const EventPost = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', title);
    formData.append('description', description);
    formData.append('image', image);

    try {
      const response = await apiClient.post('api/events-create/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status >= 200 && response.status < 300) {
        console.log('Event added successfully');
        setTitle('');
        setDescription('');
        setImage(null);
        setImagePreview('');
        toast.success('Event added successfully');
      } else {
        console.error('Failed to add event');
        toast.error('Invalid Form Data');
      }
    } catch (error) {
      console.error('Error adding event:', error);
      toast.error('Invalid Form Data');
    }
  };

  return (
    <div className="main">
      <Sidebar isOpen={isSidebarOpen} />
      <div className="content">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="container-fluid px-4 py-3">
          <div className="row">
            <div className="col">
                <h1>Add Post</h1>
            </div>
            <div className="col-6 d-flex justify-content-end">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/site">Site</Link></li>
                                    <li className="breadcrumb-item"><Link to="/site/events">Events</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Add-Event</li>
                                </ol>
                            </nav>
                        </div>
          </div>
          <div className="row mt-3 px-3">
            <div className="box">
              <div className="row">
                <form onSubmit={handleSubmit}>
                  <div className="col-12">
                    <label htmlFor="title" className='form-label mb-3'>Title</label>
                    <input type="text" className='form-control' value={title} onChange={(e) => setTitle(e.target.value)} />
                  </div>
                  <div className="col-12">
                    <label htmlFor="description" className='form-label mb-3'>Description</label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={description}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setDescription(data);
                      }}
                    />
                  </div>
                  <div className="col-3">
                    <label htmlFor="image" className='form-label mb-3'>Image</label>
                    <input type="file" className='form-control' onChange={handleImageChange} />
                    {imagePreview && <img src={imagePreview} alt="Preview" className="img-thumbnail mt-2" style={{ width: '100px' }} />}
                  </div>
                  <div className="col-12 mt-3">
                    <button type="submit" className='btn btn-primary'>Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default EventPost;
