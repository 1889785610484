import React, { useState, useEffect } from 'react';
import { Table, Modal, Button } from 'react-bootstrap';
import { FaStar, FaRegStar } from 'react-icons/fa';
import EditCourse from './EditCourse';
import defaultImage from './../assets/unknown-banner.jpg';
import apiClient from '../apiClient'; 
import { toast } from 'react-toastify';

const AllCourseList = () => {
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState('');
  const [showViewModal, setShowViewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  useEffect(() => {
    apiClient.get('api/all-course/')
      .then(response => {
        setCourses(response.data);
        setFilteredCourses(response.data);
      })
      .catch(error => console.error('Error fetching course data:', error));

    apiClient.get('api/faculties/')
      .then(response => setFaculties(response.data))
      .catch(error => console.error('Error fetching faculty data:', error));
  }, []);

  const togglePopular = (id, currentPopular) => {
    apiClient.patch(`api/courses/${id}/make_popular/`, { popular: !currentPopular })
      .then(response => {
        const updatedPopular = response.data.popular;
        setCourses(prevCourses => prevCourses.map(course =>
          course.id === id ? { ...course, popular: updatedPopular } : course
        ));
        setFilteredCourses(prevCourses => prevCourses.map(course =>
          course.id === id ? { ...course, popular: updatedPopular } : course
        ));
      })
      .catch(error => console.error('Error toggling popular status:', error));
  };

  const handleView = course => {
    setSelectedCourse(course);
    setShowViewModal(true);
  };

  const handleEdit = course => {
    setSelectedCourse(course);
    setShowEditModal(true);
  };

  const handleCloseViewModal = () => setShowViewModal(false);

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    fetchData();
  };

  const handleFacultyChange = event => {
    const faculty = event.target.value;
    setSelectedFaculty(faculty);
    if (faculty === '') {
      setFilteredCourses(courses);
    } else {
      const filtered = courses.filter(course => course.faculty.faculty === faculty);
      setFilteredCourses(filtered);
    }
  };

  const fetchData = () => {
    apiClient.get('api/all-course/')
      .then(response => {
        setCourses(response.data);
        setFilteredCourses(response.data);
      })
      .catch(error => console.error('Error fetching course data:', error));
  };

  const handleRemoveCourse = (id) => {
    if (id) {
      const formData = {
        is_active: false,
        slug: ''
      };
      apiClient.put(`api/courses/${id}/deactivate/`, formData)
        .then(response => {
          console.log('Course removed successfully:', response.data);
          fetchData(); 
          handleCloseViewModal();
        })
        .catch(error => console.error('Error removing course:', error));
    }
  };

  const capitalizeEachWord = (str) => {
    if (!str) return ''; // Handle undefined or null values
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  return (
    <div>
      <div className='row'>
        <div className="col-12">
          <label htmlFor="selectFaculty" className='form-label mb-2' style={{fontWeight:'600'}}>Filter By Faculty:</label>
          <select name="select" onChange={handleFacultyChange} value={selectedFaculty} className='form-control'>
            <option value="">All Faculties</option>
            {faculties.map(faculty => (
              <option key={faculty.id} value={faculty.faculty}>
                {capitalizeEachWord(faculty.faculty)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="box mt-3">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Duration</th>
              <th>Cost</th>
              <th>Faculty</th>
              <th className="text-center">Popular</th>
              <th className='text-center'>View</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredCourses.map((course, index) => (
              <tr key={course.id}>
                <td>{index + 1}</td>
                <td>{capitalizeEachWord(course.title)}</td>
                <td>{capitalizeEachWord(course.duration)}</td>
                <td>{course.cost}</td>
                <td>{capitalizeEachWord(course.faculty.faculty)}</td>
                <td
                  onClick={() => togglePopular(course.id, course.popular)}
                  style={{ cursor: 'pointer' }}
                  className="text-center"
                >
                  {course.popular ? <FaStar color="orange" /> : <FaRegStar color="gray" />}
                </td>
                <td className='text-center'>{course.display ? 'Public' : 'Private'}</td>
                <td className="text-center">
                  <button className="btn btn-success btn-sm mx-2" onClick={() => handleView(course)}>
                    View
                  </button>
                  <button className="btn btn-primary btn-sm mx-2" onClick={() => handleEdit(course)}>
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Modal show={showViewModal} onHide={handleCloseViewModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{selectedCourse ? capitalizeEachWord(selectedCourse.title) : 'Course Title'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCourse ? (
            <>
              <img 
                src={selectedCourse.image || defaultImage} 
                alt={`Banner for ${selectedCourse.title || 'the course'}`} 
                style={{ minWidth: '100%', height: '400px', objectFit: 'cover' }} 
              />
              <div className="row d-flex justify-content-between my-3" style={{ fontSize: '1rem' }}>
                <div className="col">
                  <p><strong>Duration: </strong>{capitalizeEachWord(selectedCourse.duration)}</p>
                </div>
                <div className="col">
                  <p><strong>Cost: </strong>{selectedCourse.cost}</p>
                </div>
              </div>
              <div className="row">
                <p dangerouslySetInnerHTML={{ __html: selectedCourse.description }}></p>
              </div>
            </>
          ) : (
            <p>Course information is not available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={() => handleRemoveCourse(selectedCourse.id)}>
            Remove Course
          </Button>
          <Button variant="secondary" onClick={handleCloseViewModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditModal} onHide={handleCloseEditModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Edit Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCourse && (
            <EditCourse 
              courseId={selectedCourse.id} 
              onSuccess={() => {
                handleCloseEditModal(); 
                toast.success('Course updated successfully!'); 
              }} 
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AllCourseList;
