import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import Navbar from '../Navbars/Navbar';
import Sidebar from '../Navbars/Sidebar';
import { Table, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import apiClient from '../apiClient';
import { FaHome } from "react-icons/fa";

const ManageAds = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showAd, setShowAd] = useState(false);
  const [selectedAd, setSelectedAd] = useState(null);

  const [formData, setFormData] = useState({
    title: '',
    link: '',
    smImg: null,
  });
  const [smImgPreview, setSmImgPreview] = useState(null);
  const [adsData, setAdsData] = useState([]);


  useEffect(() => {
    fetchAdsData();
  }, []);

  const fetchAdsData = async () => {
    try {
      const response = await apiClient.get('/api/get-all-ads/');
      setAdsData(response.data);
    } catch (error) {
      console.error('Error fetching ads data', error);
      toast.error('Failed to fetch ads data');
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleAddAdsClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormData({
      title: '',
      link: '',
      smImg: null,
    });
    setSmImgPreview(null);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSmImgChange = (event) => {
    const file = event.target.files[0];
    setFormData({
      ...formData,
      smImg: file
    });
    setSmImgPreview(URL.createObjectURL(file));
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const formDataToSend = new FormData();
    formDataToSend.append('title', formData.title);
    formDataToSend.append('link', formData.link);
    formDataToSend.append('image', formData.smImg);
  
    try {
      const response = await apiClient.post('/api/create-ad/', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.status === 201) {
        toast.success('Ad created successfully');
        handleCloseModal();
        fetchAdsData(); 
      } else if (response.status === 400) {
        toast.error(response.data.detail);
      }
    } catch (error) {
      console.error('Error creating ad', error);
      if (error.response) {
        const errorMessage = error.response.data.detail || 'Failed to create ad';
        toast.error(errorMessage);
      } else {
        toast.error('Failed to create ad');
      }
    }
  };

  const handleShowAdd = (ad) => {
    setSelectedAd(ad);
    setShowAd(true);
    fetchAdById(ad.id);
  };

  const fetchAdById = async (adId) => {
    try {
      const response = await apiClient.get(`/api/get-ad/${adId}/`);
      setSelectedAd(response.data);
    } catch (error) {
      console.error('Error fetching ad data', error);
      toast.error('Failed to fetch ad data');
    }
  };
  
  const handleDeleteAd = async (adId) => {
    try {
      const response = await apiClient.delete(`/api/delete-ad/${adId}/`);
      if (response.status === 204) {
        toast.success('Ad deleted successfully');
        fetchAdsData(); 
        setShowAd(false);
      } else {
        toast.error('Failed to delete ad');
      }
    } catch (error) {
      console.error('Error deleting ad', error);
      toast.error('Failed to delete ad');
    }
  };


  return (
    <div className="main">
      <Sidebar isOpen={isSidebarOpen} />
      <div className="content">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="container-fluid px-4 py-3">
          <div className="row align-items-center justify-content-between">
            <div className="col-auto">
              <h1>Manage Ads</h1>
            </div>
            <div className="col-auto d-flex">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/" className="text-dark" style={{ textDecoration: 'none' }}>
                      <FaHome />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/site" className="text-dark" style={{ textDecoration: 'none' }}>
                      Site
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Manage Ads</li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="row d-flex justify-content-end">
            <div className="col-auto">
                <button className='btn btn-sm btn-success' onClick={handleAddAdsClick}>Add Ads</button>
            </div>
          </div>
          <div className="row mt-3 px-3">
            <div className="box">
              <div className="col-12">
                <Table>
                  <thead>
                    <tr>
                      <th>S.N.</th>
                      <th>Title</th>
                      <th>Link</th>
                      <th className='text-center'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {adsData.map((ad, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{ad.title}</td>
                        <td>{ad.link}</td>
                        <td className='text-center'>
                        <button className='btn btn-sm btn-primary' onClick={() => handleShowAdd(ad)}>View</button>
                        </td>
                    </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Ads Source</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="mb-3">
              <label htmlFor="title" className="form-label">Title</label>
              <input type="text" className="form-control" id="title" name="title" value={formData.title} onChange={handleInputChange} required />
            </div>
            <div className="mb-3">
              <label htmlFor="link" className="form-label">Link</label>
              <input type="text" className="form-control" id="link" name="link" value={formData.link} onChange={handleInputChange} required />
            </div>
            <div className="mb-3">
              <label htmlFor="sm-img" className="form-label">Small Image</label>
              <input type="file" className="form-control" id="sm-img" name="smImg" onChange={handleSmImgChange} accept="image/*" required />
              {smImgPreview && <img src={smImgPreview} alt="Preview" className="img-fluid mt-2" />}
            </div>
            <div className="mb-3 d-flex justify-content-end">
                <div className="col-auto">
                <button type="submit" className="btn btn-primary">Add Ad Source</button>
                </div>
            </div>
          </form>
        </Modal.Body>

      </Modal>

      <Modal show={showAd} onHide={() => setShowAd(false)}>
        <Modal.Body>
            {selectedAd && (
            <div>
                <h1>{selectedAd.title}</h1>
                <img src={`${apiClient.defaults.baseURL}${selectedAd.image}`} alt={selectedAd.title} className="img-fluid" />
                <div className="d-flex justify-content-end mt-3">
                <button className='btn btn-sm btn-danger' onClick={() => handleDeleteAd(selectedAd.id)}>Delete</button>
                </div>
            </div>
            )}
        </Modal.Body>
        </Modal>


      <ToastContainer />
    </div>
  );
}

export default ManageAds;
