import React, { useState, useEffect } from 'react';
import apiClient from '../apiClient';

const Profile = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await apiClient.get('auth/api/current-user/');
        const user = response.data;
        setUserId(user.userId);
        const userDetails = await apiClient.get(`/api/user-details/${user.userId}/`);
        setFullName(userDetails.data.full_name);
        setEmail(userDetails.data.email);
        setPhone(userDetails.data.phone);
        setUsername(userDetails.data.username);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserProfile();
  }, []);

  const handleChange = (e) => {
    setMessage('');
    setError('');
    const { name, value } = e.target;
    if (name === 'fullName') setFullName(value);
    else if (name === 'email') setEmail(value);
    else if (name === 'phone') setPhone(value);
    else if (name === 'username') setUsername(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userId === null) {
      setError('Unable to retrieve user ID.');
      return;
    }
    try {
      const response = await apiClient.put(`/api/update-user/${userId}/`, {
        full_name: fullName,
        email,
        phone,
        username,
      });
      if (response.data.success) {
        setMessage(response.data.success);
        setError('');
      } else {
        setMessage('');
        setError(response.data.username || response.data.phone || response.data.error || 'Username already taken choose different one.');
      }
    } catch (error) {
      setMessage('');
      setError(error.response?.data?.error || 'Username already taken choose different one.');
    }
  };
  
  

  return (
    <div className="container">
      <h5>Update Profile</h5>
      {message && <div className="alert alert-success">{message}</div>}
      {error && <div className="alert alert-danger">{error}</div>}
      <form onSubmit={handleSubmit}>
        <div className="form-group mb-3">
          <label htmlFor="fullName" className="form-label">Full Name</label>
          <input 
            type="text" 
            className="form-control" 
            name="fullName" 
            value={fullName} 
            onChange={handleChange} 
            required 
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="email" className="form-label">Email</label>
          <input 
            type="email" 
            className="form-control" 
            name="email" 
            value={email} 
            onChange={handleChange} 
            required 
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="phone" className="form-label">Phone</label>
          <input 
            type="text" 
            className="form-control" 
            name="phone" 
            value={phone} 
            onChange={handleChange} 
            required 
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="username" className="form-label">Username</label>
          <input 
            type="text" 
            className="form-control" 
            name="username" 
            value={username} 
            onChange={handleChange} 
            required 
          />
        </div>
        <div className="row d-flex justify-content-end">
            <div className="col-auto">
                <button type="submit" className="btn btn-primary">Update Profile</button>
            </div>
        </div>
      </form>
    </div>
  );
};

export default Profile;
