import React, { useState } from 'react';
import Navbar from '../Navbars/Navbar';
import Sidebar from '../Navbars/Sidebar';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import './Site.css';
import AddBanner from './AddBanner';
import { ToastContainer } from 'react-toastify';
import BannerData from './BannerData';

const Site = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    return (
        <div className="main">
            <Sidebar isOpen={isSidebarOpen} />
            <div className="content">
                <Navbar toggleSidebar={toggleSidebar} />
                <div className="container-fluid px-4 py-3">
                    <div className="row">
                        <div className="col-auto">
                            <h1>Site</h1>
                        </div>
                    </div>
                    <div className="box text-center mt-3">
                        <div className="row d-flex justify-content-between">
                            <div className="col-auto">
                                <Link className='btn btn-link-custom' style={{ backgroundColor: '#9C27B0', width: '100%' }} to={'/site/events'}>
                                    <i className="far fa-calendar-alt"></i>
                                    <span style={{ marginLeft: '10px' }}>Events</span>
                                </Link>
                            </div>
                            <div className="col-auto">
                                <Link className='btn btn-link-custom' style={{ backgroundColor: '#2196F3', width: '100%' }} to={'/site/syllabus'}>
                                    <i className="fas fa-book-open"></i>
                                    <span style={{ marginLeft: '10px' }}>Syllabus</span>
                                </Link>
                            </div>
                            <div className="col-auto">
                                <Link className='btn btn-link-custom' style={{ backgroundColor: '#4CAF50', width: '100%' }} to={'/site/testimonials'}>
                                    <i className="far fa-comments"></i>
                                    <span style={{ marginLeft: '10px' }}>Testimonials</span>
                                </Link>
                            </div>
                            <div className="col-auto">
                                <Link className='btn btn-link-custom' style={{ backgroundColor: '#AF601A', width: '100%' }} to={'/site/manage-ads'}>
                                    <i className="far fa-comments"></i>
                                    <span style={{ marginLeft: '10px' }}>Manage Ads</span>
                                </Link>
                            </div>
                            <div className="col-auto">
                            <Link className='btn btn-link-custom' style={{ backgroundColor: '#1A5276', width: '100%' }} to={'/site/downloads'}>
                            <i class="fa-solid fa-arrow-down"></i>                                
                            <span style={{ marginLeft: '10px' }}>Manage Downloads</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="row d-flex justify-content-end mt-3 mb-3">
                            <div className="col-auto">
                                <Button variant="primary" size="sm" onClick={() => setShowModal(true)}>Add Banner</Button>
                            </div>
                        </div>
                        <div className="row">
                           <BannerData/>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Banner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddBanner onBannerUploadSuccess={() => setShowModal(false)} />
                </Modal.Body>
            </Modal>
            <ToastContainer />
        </div>
    );
}

export default Site;
