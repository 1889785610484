import React, { useState, useEffect } from 'react';
import Navbar from '../Navbars/Navbar';
import Sidebar from '../Navbars/Sidebar';
import './Settings.css'; 
import ManageFaculty from './ManageFaculty';
import ChangePassword from './ChangePassword';
import CreateStaff from './CreateStaff';
import CreateTeacher from './CreateTeacher';
import { ToastContainer } from 'react-bootstrap';
import apiClient from '../apiClient';
import ListStaff from './ListStaff';
import Profile from './Profile';

const Settings = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [userRole, setUserRole] = useState(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const response = await apiClient.get('api/user-role/');
        setUserRole(response.data);
      } catch (error) {
        console.error('Error fetching faculties:', error);
      }
    };
    fetchRole();
  }, []);

  return (
    <div className="main">
      <Sidebar isOpen={isSidebarOpen} />
      <div className="content">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="container-fluid px-4 py-3">
          <div className="row">
            <div className="col-12">
            <h1 className="settings-heading"><i className="fa-solid fa-gear"></i> Settings</h1>
            </div>
          </div>
          <div className="row mt-3">
          {userRole && userRole.role === 'admin' && (
            <div className="col-md-3">
              <ul className="nav flex-column">
              <li className="settings-nav-item">
                  <button
                    className={`nav-link ${activeTab === 1 ? 'active' : ''}`}
                    onClick={() => handleTabClick(1)}
                  >
                    Profile Management
                  </button>
                </li>
                <li className="settings-nav-item">
                  <button
                    className={`nav-link ${activeTab === 2 ? 'active' : ''}`}
                    onClick={() => handleTabClick(2)}
                  >
                    Password Management
                  </button>
                </li>
                <li className="settings-nav-item">
                  <button
                    className={`nav-link ${activeTab === 3 ? 'active' : ''}`}
                    onClick={() => handleTabClick(3)}
                  >
                    Faculty
                  </button>
                </li>
                <li className="settings-nav-item">
                  <button
                    className={`nav-link ${activeTab === 4 ? 'active' : ''}`}
                    onClick={() => handleTabClick(4)}
                  >
                    Create Staffs
                  </button>
                </li>   
                <li className="settings-nav-item">
                  <button
                    className={`nav-link ${activeTab === 5 ? 'active' : ''}`}
                    onClick={() => handleTabClick(5)}
                  >
                    Create Teacher
                  </button>
                </li>  
                <li className="settings-nav-item">
                  <button
                    className={`nav-link ${activeTab === 6 ? 'active' : ''}`}
                    onClick={() => handleTabClick(6)}
                  >
                    List Staff
                  </button>
                </li>            
              </ul>
            </div>
          )
          }
          {
            userRole && userRole.role === 'staff' && (
              <div className="col-md-3">
              <ul className="nav flex-column">
              <li className="settings-nav-item">
                  <button
                    className={`nav-link ${activeTab === 1 ? 'active' : ''}`}
                    onClick={() => handleTabClick(1)}
                  >
                    Profile Management
                  </button>
                </li>
                <li className="settings-nav-item">
                  <button
                    className={`nav-link ${activeTab === 2 ? 'active' : ''}`}
                    onClick={() => handleTabClick(2)}
                  >
                    Password Management
                  </button>
                </li>
                <li className="settings-nav-item">
                  <button
                    className={`nav-link ${activeTab === 5 ? 'active' : ''}`}
                    onClick={() => handleTabClick(5)}
                  >
                    Create Teacher
                  </button>
                </li>          
              </ul>
            </div>
            )
          }
            <div className="col-md-9">
              <div className="tab-content">
              <div
                  className={`tab-pane fade ${activeTab === 1 ? 'show active' : ''}`}
                  id="tab1"
                  role="tabpanel"
                >
                  <Profile/>
                </div>
                <div
                  className={`tab-pane fade ${activeTab === 2 ? 'show active' : ''}`}
                  id="tab2"
                  role="tabpanel"
                >
                  <ChangePassword/>
                </div>
                <div
                  className={`tab-pane fade ${activeTab === 3 ? 'show active' : ''}`}
                  id="tab3"
                  role="tabpanel"
                >
                  <ManageFaculty/>
                </div>
                <div
                  className={`tab-pane fade ${activeTab === 4 ? 'show active' : ''}`}
                  id="tab4"
                  role="tabpanel"
                >
                  <CreateStaff/>
                </div>
                <div
                  className={`tab-pane fade ${activeTab === 5 ? 'show active' : ''}`}
                  id="tab5"
                  role="tabpanel"
                >
                  <CreateTeacher/>
                </div>
                <div
                  className={`tab-pane fade ${activeTab === 6 ? 'show active' : ''}`}
                  id="tab6"
                  role="tabpanel"
                >
                  <ListStaff/>
                </div>
              </div>
              <ToastContainer/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
