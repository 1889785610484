import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import Navbar from '../Navbars/Navbar';
import Sidebar from '../Navbars/Sidebar';
import apiClient from '../apiClient';
import { Table, Modal } from 'react-bootstrap';
import EditPost from './EditPost';
import { ToastContainer, toast } from 'react-toastify';
import { FaHome } from "react-icons/fa";

const Events = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [postId, setPostId] = useState(null); 

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const fetchEvents = async () => {
    try {
      const response = await apiClient.get('/api/events/');
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const handleView = (event) => {
    setSelectedEvent(event);
    setShowViewModal(true);
  };

  const handleEdit = (event) => {
    setSelectedEvent(event);
    setPostId(event.id);
    setShowEditModal(true);
  };

  const handleDelete = async (event) => {
    try {
      await apiClient.delete(`/api/events-delete/${event.id}/`);
      toast.success('Post deleted successfully');
      fetchEvents();
    } catch (error) {
      console.error('Error deleting event:', error);
      toast.error('Error deleting event:', error);
    }
  };
  return (
    <div className="main">
      <Sidebar isOpen={isSidebarOpen} />
      <div className="content">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="container-fluid px-4 py-3">
          <div className="row align-items-center justify-content-between">
            <div className="col-auto">
              <h1>Events</h1>
            </div>
            <div className="col-auto d-flex">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/" className="text-dark" style={{ textDecoration: 'none' }}>
                      <FaHome />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/site" className="text-dark" style={{ textDecoration: 'none' }}>
                      Site
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Events</li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="row d-flex justify-content-end">
            <div className="col-auto">
            <Link to={'/site/events/add-post'} className='btn btn-success btn-sm'>Add Post</Link>

            </div>
          </div>
          <div className="row mt-3 px-3">
            <div className="box">
              <div className="col-12">
                <Table>
                  <thead>
                    <tr>
                      <th>S.N.</th>
                      <th>Title</th>
                      <th>Posted Date</th>
                      <th className='text-center'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {events.map((event, index) => (
                      <tr key={event.id}>
                        <td>{index + 1}</td>
                        <td>{event.name}</td>
                        <td>{formatDate(event.posted_date)}</td>
                        <td className='text-center'>
                          <button className='btn btn-sm btn-success mx-2' onClick={() => handleView(event)}>View</button>
                          <button className='btn btn-sm btn-primary mx-2' onClick={() => handleEdit(event)}>Edit</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showViewModal} onHide={() => setShowViewModal(false)} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{selectedEvent && selectedEvent.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEvent && (
            <div>
              <img src={selectedEvent.image} alt="banner" style={{ width: '100%' }} />
              <div className="row d-flex justify-content-end py-2">
                <div className="col-auto">
                  <p><strong>Posted Date: </strong><i>{formatDate(selectedEvent.posted_date)}</i></p>
                </div>
              </div>
              <div dangerouslySetInnerHTML={{ __html: selectedEvent.description }} style={{ zIndex: 1 }} />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
            <button className='btn btn-danger' onClick={() => { handleDelete(selectedEvent); setShowViewModal(false); }}>Delete Post</button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditModal} onHide={() => {
            setShowEditModal(false);
            fetchEvents(); 
        }} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Edit Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditPost postId={postId} />
        </Modal.Body>
      </Modal>
      <ToastContainer />

    </div>
  );
}

export default Events;
