import React, { useState, useEffect, useCallback } from 'react';
import logo from './../assets/logo.webp';
import apiClient from '../apiClient';


const UserBilling = ({ userId }) => {
  const [userData, setUserData] = useState(null);
  const [courseData, setCourseData] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [billingData, setBillingData] = useState([]);
  const [totalPaid, setTotalPaid] = useState(0);
  const [dueAmount, setDueAmount] = useState(null);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [formData, setFormData] = useState({
    amount: '',
    in_word: '',
    discount: '',
    remark: '',
    is_admission: false,
    is_tuition: false,
    is_computer: false, 
    is_language: false,
    is_bridge_course: false,
    is_entrance: false,
    is_training: false,
    is_student_fee: false, 
    is_stationery: false, 
    is_other: false,
  });
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await apiClient.get(`api/user/${userId}/`);
        setUserData(userResponse.data);

        const courseResponse = await apiClient.get(`api/all-active-inactive/${userId}/`);
        const courseData = courseResponse.data;
        setCourseData(courseData);

        let totalCost = 0;
        courseData.forEach(course => {
          totalCost += parseFloat(course.course.cost);
        });
        setTotalCost(totalCost);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchDueAmount = async () => {
        try {
            const response = await apiClient.get(`api/only-due/${userId}/`);
            setDueAmount(response.data.due_amount);
        } catch (error) {
            console.error('Error fetching due amount:', error);
        }
    };

    const fetchUserCourse = async() => {
      try{
        const selectedCoursesResponse = await apiClient.get(`api/courses/user/${userId}/`);
        setSelectedCourses(selectedCoursesResponse.data);
        console.log(setSelectedCourses);
      } catch (error) {
        console.error('Error fetching data:', error);
      }    }

    fetchDueAmount();

    fetchData();
    fetchUserCourse();

  }, [userId]);
  
  const updateFinancials = async () => {
    const fetchDueAmount = async () => {
      try {
        const response = await apiClient.get(`api/only-due/${userId}/`);
        setDueAmount(response.data.due_amount);
      } catch (error) {
        console.error('Error fetching due amount:', error);
      }
    };
  
    await fetchBillingData();
    await fetchDueAmount(); 
  };
  
  const fetchBillingData = useCallback(async () => {
    try {
      const response = await apiClient.get(`api/bills/${userId}/`);
      const billData = response.data;
      setBillingData(billData);

      let totalPaid = 0;
      billData.forEach(bill => {
        totalPaid += parseFloat(bill.amount);
      });
      setTotalPaid(totalPaid);
    } catch (error) {
      console.error('Error fetching billing data:', error);
    }
  }, [userId]);

  useEffect(() => {
    fetchBillingData();
  }, [userId, fetchBillingData]);
  



  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
    return formattedDate;
  }
  


  const handleChange = (e) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [e.target.name]: e.target.value
    }));
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const reception = localStorage.getItem('username');
    console.log('Receptionist ID:', reception);
    try {
      const formBooleanValues = {
        is_admission: formData.is_admission,
        is_tuition: formData.is_tuition,
        is_computer: formData.is_computer,
        is_language: formData.is_language,
        is_bridge_course: formData.is_bridge_course,
        is_entrance_preparation: formData.is_entrance,
        is_training: formData.is_training,
        is_student: formData.is_student_fee,
        is_stationery: formData.is_stationery,
        is_other: formData.is_other,
      };
  
      Object.keys(formBooleanValues).forEach(key => {
        if (!formBooleanValues[key]) {
          delete formBooleanValues[key];
        }
      });
  
      const response = await apiClient.post('api/create-billing/', {
        ...formData,
        ...formBooleanValues,
        discount: formData.discount ? formData.discount : 0,
        user: userId,
        reception: reception,
      });
      console.log('Payment successful:', response.data);
      await updateFinancials(); // Call updateFinancials to update financial data
      setFormData({
        amount: '',
        in_word: '',
        discount: '',
        remark: '',
        is_admission: false,
        is_tuition: false,
        is_computer: false,
        is_language: false,
        is_bridge_course: false,
        is_entrance_preparation: false,
        is_training: false,
        is_student: false,
        is_stationery: false,
        is_other: false,
      });
    } catch (error) {
      console.error('Error making payment:', error);
    }
  };
  
  

  
  const handlePrintInvoice = (bill) => {
    const courseNames = selectedCourses.map(course => course.course.title).join(", ");
    const printableContent = `

    <div class="row" style="text-align: center; font-family: Arial, Helvetica, sans-serif;">
    <div class="logo" style="position: absolute; z-index: 999; top: 1rem; left: 2rem;">
        <img src=${logo} alt="logo" width="100px" height="100px">
    </div>
    <h1 style="font-size: 30px; font-weight: 600;"> Golden Future Institute</h1>
    <p style="font-size: 16px; font-weight: 600; line-height: 22px;">Satungal, Chandragiri-11, Kathmandu, (Opp to APF Gate)<br>
        Email: goldenfutureinstitute0@gmail.com <br>
        Website: www.goldenfutureinstitute.com.np
    </p>
    <div class="row" style="text-align: left;">
        <div class="userData" style="border: 1px solid #ccc; padding: 10px;">
            <div class="row1" style="display: flex; justify-content: space-between;">
                <div class="pan">
                    <p style="display: flex; align-items: center;">PAN No.
                    <table style="width: 100%; border-collapse: collapse;">
                      <thead>
                      <tr>
                      <th style="border: 1px solid #000; padding: 3px;">6</th>
                      <th style="border: 1px solid #000; padding: 3px;">1</th>
                      <th style="border: 1px solid #000; padding: 3px;">4</th>
                      <th style="border: 1px solid #000; padding: 3px;">0</th>
                      <th style="border: 1px solid #000; padding: 3px;">1</th>
                      <th style="border: 1px solid #000; padding: 3px;">6</th>
                      <th style="border: 1px solid #000; padding: 3px;">4</th>
                      <th style="border: 1px solid #000; padding: 3px;">5</th>
                      <th style="border: 1px solid #000; padding: 3px;">1</th>
                      </tr>
                      </thead>
                    </table>
                    </p>
                </div>
                <div class="date">
                <p><strong>Date: ${new Date(bill.date).toLocaleDateString()}</strong></p>
                </div>
            </div>
            <div class="user-info" style="margin: 0; padding: 0;">
                <div class="row">
                    <p><Strong>Name:</Strong> ${capitalizeTitle(bill.user.full_name)}</p>
                    <p><Strong>Course:</Strong> ${capitalizeTitle(courseNames)}</p>
                    <p><Strong>Invoice No.: ${bill.invoice_number}</Strong></p>
                </div>
            </div>
        </div>
        <div class="table" style="width: 100%; margin-top: 2rem;">
            <table style="width: 100%; border-collapse: collapse;">
                <thead>
                    <tr>
                        <th style="width: 10%; text-align: center; border: 1px solid #000;">S.N.</th>
                        <th style="width: 70%; text-align: left; border:1px solid #000; padding: 5px 10px;">Courses</th>
                        <th style="width: 20%; text-align: center; border: 1px solid #000;">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style="margin-left: 1px;">
                        <th style="text-align: center; border-right: 1px solid #000; border-left: 1px solid #000 ; padding: 5px 20px;">1</th>
                        <th style="width: 70%; text-align: left; padding: 5px 10px;">Admission Form Fee ${bill.is_admission ?  `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
    </svg>` : ''}</th>
                        <th style="text-align: center; vertical-align: top; border-left:1px solid #000; border-right:1px solid #000;" rowspan="10" padding:"5px 10px">${bill.amount}</th>
                      </tr>
                    <tr>
                        <th style="text-align: center; border-right: 1px solid #000; border-left: 1px solid #000; padding: 5px 20px;">2</th>
                        <th style="width: 70%; text-align: left; padding: 5px 10px;">
                        Tuition Fee ${bill.is_tuition ? `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                      </svg>` : ''}
                    </th>
                                        </tr>
                    <tr>
                        <th style="text-align: center; border-right: 1px solid #000; border-left: 1px solid #000; padding: 5px 20px;">3</th>
                        <th style="width: 70%; text-align: left; padding: 5px 10px;">Computer Fee ${bill.is_computer ? `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                      </svg>`  : ''}</th>
                    </tr>
                    <tr>
                        <th style="text-align: center; border-right: 1px solid #000; border-left: 1px solid #000; padding: 5px 20px;">4</th>
                        <th style="width: 70%; text-align: left; padding: 5px 10px;">Language ${bill.is_language ? `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
    </svg>`  : ''}</th>
                    </tr>
                    <tr>
                        <th style="text-align: center; border-right: 1px solid #000; border-left: 1px solid #000; padding: 5px 20px;">5</th>
                        <th style="width: 70%; text-align: left; padding: 5px 10px;">Bridge Course Fee ${bill.is_bridge_course ? `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
    </svg>`  : ''}</th>
                    </tr>
                    <tr>
                        <th style="text-align: center; border-right: 1px solid #000; border-left: 1px solid #000; padding: 5px 20px;">6</th>
                        <th style="width: 70%; text-align: left; padding: 5px 10px;">Entrance Preparation Fee ${bill.is_entrance_preparation ? `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
    </svg>`  : ''}</th>
                    </tr>
                    <tr>
                        <th style="text-align: center; border-right: 1px solid #000; border-left: 1px solid #000; padding: 5px 20px;">7</th>
                        <th style="width: 70%; text-align: left; padding: 5px 10px;">Training Fee ${bill.is_training ? `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
    </svg>`  : ''}</th>
                    </tr>
                    <tr>
                        <th style="text-align: center; border-right: 1px solid #000; border-left: 1px solid #000; padding: 5px 20px;">8</th>
                        <th style="width: 70%; text-align: left; padding: 5px 10px;">Student Fee ${bill.is_student ? `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
    </svg>`  : ''}</th>
                    </tr>
                    <tr>
                        <th style="text-align: center; border-right: 1px solid #000; border-left: 1px solid #000; padding: 5px 20px;">9</th>
                        <th style="width: 70%; text-align: left; padding: 5px 10px;">Stationery Fee ${bill.is_stationery ? `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
    </svg>`  : ''}</th>
                    </tr>
                    <tr>
                        <th style="text-align: center; border-right: 1px solid #000; border-left: 1px solid #000; padding: 5px 20px;">10</th>
                        <th style="width: 70%; text-align: left; padding: 5px 10px;">Other ${bill.is_other ? `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
    </svg>`  : ''}</th>
                    </tr>
     
                    <tr>
                        <th colspan="2" style="border: 1px solid #000; text-align: right; padding: 5px 20px;">Total Amount</th>
                        <th style="border: 1px solid #000; text-align: right; padding: 5px 20px;">${ totalCost + 100 || 'N/A'}</th>
                    </tr>
                    <tr>
                        <th colspan="2" style="border: 1px solid #000; text-align: right; padding: 5px 20px;">Total Paid</th>
                        <th style="border: 1px solid #000; text-align: right; padding: 5px 20px;">${bill.amount}</th>
                    </tr>
                    <tr>
                        <th colspan="2" style="border: 1px solid #000; text-align: right; padding: 5px 20px;">Discount</th>
                        <th style="border: 1px solid #000; text-align: right; padding: 5px 20px;">${bill.discount}</th>
                    </tr>
                    <tr>
                        <th colspan="2" style="border: 1px solid #000; text-align: right; padding: 5px 20px;">Due Amount</th>
                        <th style="border: 1px solid #000; text-align: right; padding: 5px 20px;">${totalPaid > 100 ? (Number(dueAmount) + 100) : (Number(dueAmount) + 100)}</th>
                    </tr>
                </tbody>
            </table>
            </br>
            <p>
                <strong>In Word: </strong>
                ${bill.in_word}.
            </p>
        </div>
        </br>
        <div class="receiption" style="align-items: end; text-align: end; margin-top: 50px;">
            <p>Receptationist: ${bill.reception}</p>
        </div>
        </br>
        <div style="text-align: center;">
        <p>This is computer generated invoice, no signagure required.</p>
        </div>
    </div>
  
</div>
    `;
  
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    
    const iframeDocument = iframe.contentWindow.document;
    iframeDocument.open();
    iframeDocument.write(printableContent);
    iframeDocument.close();
    
    iframe.contentWindow.print();
    
    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 1000); 
  };
  
  
  
  
  if (!userData || !courseData) {
    return <div>Loading...</div>;
  }
 
  const capitalizeTitle = (title) => 
    title.replace(/\b\w/g, char => char.toUpperCase());
  return (
    <div>
        <div className="row">
            <div className="col-8">
                <div className="col-12 mb-3" style={{background:'#27AE60'}}>
                    <p style={{ padding: '5px 10px', margin: '0', 'color':'white' }}><strong>User Information</strong></p>
                </div>
                <div className="col-12 d-flex justify-content-between">
                    <p className='mb-0'><strong>Full Name: </strong>{capitalizeTitle(userData.full_name || 'N/A')}</p>
                    <p className='mb-0'><strong>Date of Join: </strong>{userData.created_at ? new Date(userData.created_at).toLocaleDateString('en-US', { month: 'long', day: '2-digit', year: 'numeric' }) : 'N/A'}</p>
                    <p className='mb-0'><strong>Phone: </strong>{userData.phone  || 'N/A'}</p>
                </div>
                <div className="col-12 mb-3 my-3" style={{background:'#27AE60'}}>
                    <p style={{ padding: '5px 10px', margin: '0', 'color':'white' }}><strong>Course Information</strong></p>
                </div>
                <div className="col-12">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Course Title</th>
                        <th>Shift</th>
                        <th>Joined Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {courseData.slice().reverse().map((course, index) => (
                        <tr key={course.id}>
                          <td>{index + 1}</td>
                          <td>{capitalizeTitle(course.course.title)}</td>
                          <td>{capitalizeTitle(course.shift.shiftname)}</td>
                          <td>{course.date}</td>
                          <td>{course.active_status ? 'Active' : 'Inactive'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="col-12 mb-3 my-3" style={{background:'#27AE60'}}>
                    <p style={{ padding: '5px 10px', margin: '0', 'color':'white' }}><strong>Amount Information</strong></p>
                </div>
                <div className="col-12 d-flex justify-content-between">
                  <p className='mb-0'><strong>Total: </strong>Nrs.{ totalCost + 100 || 'N/A'}</p>
                    <p className='mb-0'><strong>Paid: </strong>{totalPaid || 'N/A'}</p>
                    {dueAmount !== null ? (
                      <>
                        <p className='mb-0'>
                          <strong>Admission Fee:</strong> {totalPaid > 100 ? 'Paid' : 'Unpaid'}  
                        </p>
                        <p>
                          <strong>Due Amount: </strong>
                          {totalPaid > 100 ? (Number(dueAmount) + 100) : (Number(dueAmount) + 100)}
                        </p>
                      </>
                    ) : (
                      <p>Loading due amount...</p>
                    )}
                </div>
            </div>
            <div className="col-4">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="amount">Amount</label>
                <input type="number" className="form-control my-1" name="amount" value={formData.amount} onChange={handleChange} required/>
                <label htmlFor="in_word">Amount in Word</label>
                <input type="text" className="form-control my-1" name="in_word" value={formData.in_word} onChange={handleChange} required/>
                <label htmlFor="discount">Discount Amount</label>
                <input type="number" className="form-control my-1" name="discount" value={formData.discount}onChange={handleChange}/>
                <label htmlFor="remark">Remark</label>
                <input type="text" className="form-control" name="remark" value={formData.remark} onChange={handleChange}required/>
              </div>
              <div className="row">
                <label htmlFor="field">Fee Category</label>
              </div>
              <div className="row my-2">
                <div className="form-check" style={{display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', fontSize: '12px'}}>
                  {Object.keys(formData).filter(key => key.startsWith('is_')).map((feeType) => (
                    <div className="col" key={feeType}>
                      <input 
                        type="checkbox" 
                        name={feeType} 
                        checked={formData[feeType]} 
                        onChange={handleChange} 
                      /> {feeType.replace('is_', '').replace(/_/g, ' ').toUpperCase()}
                    </div>
                  ))}
                </div>
              </div>
              <div className="row d-flex px-3">
                <button type="submit" className="btn btn-primary ml-2">Pay Now</button>
              </div>
            </form>
                
            </div>
            <div className="col-12">
                <div className="row mb-3 my-3" style={{background:'#27AE60'}}>
                    <p style={{ padding: '5px 10px', margin: '0', 'color':'white' }}><strong>Payment History</strong></p>
                </div>
                <div className="row">
                {billingData.length > 0 ? (

                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Invoice</th>
                      <th>Amount</th>
                      <th>Discount</th>
                      <th>Remark</th>
                      <th>Receptionist</th>
                      <th className='text-center'>Voucher</th>
                    </tr>
                  </thead>
                  <tbody>
                  {billingData.slice().reverse().map((bill) => (
                    <tr key={bill.id}>
                      <td>{formatDate(bill.date)}</td>
                      <td>{bill.invoice_number}</td>
                      <td>{bill.amount}</td>
                      <td>{bill.discount}</td>
                      <td>{bill.remark}</td>
                      <td>{bill.reception}</td>
                      <td className='text-center'>
                        <button
                          className='btn btn-sm btn-primary'
                          onClick={() => handlePrintInvoice(bill)}
                        >
                          Print Invoice
                        </button>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
                ) : (
                  <p>No billing data available</p>
                )}
                </div>
                </div>

        </div>
        
    </div>
  );
}

export default UserBilling;
