import React, { useState, useEffect } from 'react';
import apiClient from '../apiClient';
import { toast } from 'react-toastify';

const AddShift = ({ onShiftAdded }) => {
    const [faculties, setFaculties] = useState([]);
    const [selectedFaculty, setSelectedFaculty] = useState('');
    const [courses, setCourses] = useState([]);
    const [shiftName, setShiftName] = useState('');
    const [loading, setLoading] = useState(false); 

    useEffect(() => {
      apiClient.get('api/faculties/')
        .then(response => setFaculties(response.data))
        .catch(error => console.error('Error fetching faculties:', error));
    }, []);

    useEffect(() => {
      if (selectedFaculty) {
        apiClient.get(`api/courses/${selectedFaculty}/filter/`)
          .then(response => setCourses(response.data.courses))
          .catch(error => console.error('Error fetching courses:', error));
      } else {
        setCourses([]);
      }
    }, [selectedFaculty]);

    const handleAddShift = (event) => {
      event.preventDefault();
      setLoading(true); 

      const formData = {
        faculty: event.target.faculty.value,
        course: event.target.course.value,
        shiftname: shiftName
      };

      apiClient.post('api/shifts/create/', formData)
        .then(response => {
          toast.success('Shift added successfully!');
          setShiftName('');
          event.target.reset();
          onShiftAdded(); 
        })
        .catch(error => {
          toast.error('Error adding shift.');
          console.error('Error creating shift:', error);
        })
        .finally(() => {
          setLoading(false); 
        });
    };

    const capitalizeEachWord = (str) => {
      if (!str) return '';
      return str
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    };

    return (
      <div>
        <div className="row">
          <form onSubmit={handleAddShift} className="row">
            <div className="col-12">
              <div className="row">
                <div className="col">
                  <label htmlFor="selectFaculty" className='form-label mb-2'>Select Faculty:</label>
                  <select name="faculty" id="faculty" className='form-control' onChange={(e) => setSelectedFaculty(e.target.value)}>
                    <option value="">Select Faculty</option>
                    {faculties.map(faculty => (
                      <option key={faculty.id} value={faculty.id}>{capitalizeEachWord(faculty.faculty)}</option>
                    ))}
                  </select>
                </div>
                <div className="col">
                  <label htmlFor="selectCourse" className='form-label mb-2'>Select Course</label>
                  <select name="course" id="course" className='form-control'>
                    <option value="">Select Course</option>
                    {Array.isArray(courses) && courses.map(course => (
                      <option key={course.id} value={course.id}>{capitalizeEachWord(course.title)}</option>
                    ))}
                  </select>
                </div>
                <div className="col">
                  <label htmlFor="shiftName" className='form-label mb-2'>Shift Name</label>
                  <input type="text" className='form-control' name='shift' value={shiftName} onChange={(e) => setShiftName(e.target.value)} />
                </div>
                <div className="col d-flex align-items-end">
                  <button type="submit" className='btn btn-primary'>
                    {loading ? 'Adding...' : 'Add Shift'}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
}
export default AddShift;