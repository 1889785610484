import React, { useState, useEffect } from 'react';
import apiClient from '../apiClient';
import Table from 'react-bootstrap/Table';
const Teachers = () => {
  const [teachers, setTeachers] = useState([]);

  useEffect(() => {
    fetchTeachers();
  }, []);

  const fetchTeachers = async () => {
    try {
      const response = await apiClient.get('/api/active-teachers/');
      setTeachers(response.data);
    } catch (error) {
      console.error('Error fetching teachers:', error);
    }
  };
  
  

  return (
    <div className="box">
    <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Full Name</th>
            <th>Email</th>
            <th>Username</th>
            <th>Address</th>
            <th>Phone</th>
            <th>Guardian</th>
          </tr>
        </thead>
        <tbody>
          {teachers.map((teacher) => (
            <tr key={teacher.id}>
              <td>{teacher.id}</td>
              <td>{teacher.full_name}</td>
              <td>{teacher.email}</td>
              <td>{teacher.username}</td>
              <td>{teacher.address}</td>
              <td>{teacher.phone}</td>
              <td>{teacher.guardian}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Teachers;
