import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table'; 
import Modal from 'react-bootstrap/Modal'; 
import apiClient from '../apiClient';
import { Tabs, Tab } from 'react-bootstrap';
import UserProfile from './UserProfile';
import AllSelectedCourses from './AllSelectedCourses';
import { toast, Bounce } from 'react-toastify';
import AddCourseToExistingUser from './AddCourseToExistingUser';

const AccountWithDeactiveCourse = ({ onDataUpdated }) => { 
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    apiClient.get('api/inactive-course-selected/')
      .then(response => {
        // Filter out duplicate user IDs
        const uniqueUserIds = new Set();
        const uniqueData = response.data.filter(item => {
          if (uniqueUserIds.has(item.user.id)) {
            return false;
          } else {
            uniqueUserIds.add(item.user.id);
            return true;
          }
        });
        setData(uniqueData);
      })
      .catch(error => console.error('Error fetching data:', error));
  };

  const openModal = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleDeactivateAccount = async () => {
    try {
        const response = await apiClient.post(`api/deactivate-user/${selectedItem.user.id}/`, { is_deactive: true, is_active: false });
        console.log('User deactivated:', response.data);
        setShowModal(false);
        fetchData();
        onDataUpdated(); 
        toast.success('User deactivated Successfully.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
            });
    } catch (error) {
        console.error('Error deactivating user:', error);
    }
};

const handleReactivateAccount = async () => {
  try {
      const response = await apiClient.post(`api/reactivate-user/${selectedItem.user.id}/`, { is_deactive: false , is_active: true});
      console.log('User reactivated:', response.data);
      setShowModal(false);
      fetchData();
      onDataUpdated(); 
      toast.success('User reactivated Successfully.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
          });
  } catch (error) {
      console.error('Error reactivating user:', error);
  }
};

  return (
    <div>
       <div className="box">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Address</th>
                    <th>Phone</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.user.full_name}</td>
                      <td>{item.user.email}</td>
                      <td>{item.user.address}</td>
                      <td>{item.user.phone}</td>
                      <td>
                        <button className='btn btn-success btn-sm' onClick={() => openModal(item)}>View Details</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <Modal show={showModal} onHide={closeModal} size='lg'>
              <Modal.Header closeButton>
                <Modal.Title>User Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Tabs defaultActiveKey="tab1">
                  <Tab eventKey="tab1" title="Personal Information">
                    <UserProfile userId={selectedItem ? selectedItem.user.id : null} /> 
                    <AllSelectedCourses userId={selectedItem ? selectedItem.user.id : null} />  
                  </Tab>
                  <Tab eventKey="tab2" title="Course Information">
                  <AddCourseToExistingUser userId={selectedItem ? selectedItem.user.id : null} />
                  </Tab>
                </Tabs>
              </Modal.Body>
              <Modal.Footer>
                <button className='btn btn-danger btn-sm' onClick={handleDeactivateAccount}>Deactivate Account</button>
                <button className='btn btn-primary btn-sm' onClick={handleReactivateAccount}>Reactivate Account</button>
              </Modal.Footer>
            </Modal>
    </div>
  );
};

export default AccountWithDeactiveCourse;
